import { React, useEffect, useState } from "react";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import NavigationSection from "./NavigationSection";
import {
  url,
  urlResource,
  getUserInfo,
  getAjiraToken,
  setUserInfo,
} from "../../utility/Common";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import axios from "axios";
import { createNotification } from "../Popup";

function NewAppInfo() {
  const navigate = useNavigate();

  const [Disability, setDisability] = useState("");
  const [PoBox, setPoBox] = useState("");
  const [KoziData, setKoziData] = useState([]);
  const [KoziCode, setKoziCode] = useState([]);
  const [EntryCretariaID, setEntryCretariaID] = useState([]);
  const [EntryCreteriaData, setEntryCreteriaData] = useState([]);
  const [TerritoryID, setTerritoryID] = useState("");
  const [StreetName, setStreetName] = useState("");
  const [TerritoryData, setTerritoryData] = useState([]);
  const [RegionData, setRegionData] = useState([]);
  const [DistrictData, setDistrictData] = useState([]);
  const [WardData, setWardData] = useState([]);
  const [RegionByTerritory, setRegionByTerritory] = useState("");
  const [DistrictByRegion, setDistrictByRegion] = useState("");
  const [WardID, setWardID] = useState([]);
  const [Commissioner, setCommissioner] = useState("");
  const [CommissionerData, setCommissionerData] = useState([]);
  const [ProfessionalAppliedData, setProfessionalAppliedData] = useState([]);
  const [ProfessionalAppliedID, setProfessionalAppliedID] = useState([]);
  const [callBack, setcallBack] = useState(null);

  //----------------------------------------submitFunction
  const NewApplication = {
    SystemId: "UHM005",
    ManagementDataType: "CreateApplication",
    ResourceType: "INSERT",
    KoziCode,
    ApplicantID: getUserInfo()?.ApplicantID,
    Disability,
    WardID,
    TerritoryID,
    StreetName,
    RegionByTerritory,
    EntryCretariaID,
    PoBox,
    ProfessionalAppliedID,
    AccessorId: getUserInfo()?.ApplicantID,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = getAjiraToken();
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        NewApplication,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = response?.data;
      const code = data?.code;
      const appId = response?.data?.data[0];
      if (code == 1) {
        setcallBack(callBack + 1);
        if (appId) {
          setUserInfo(appId);
          var msg = response?.data?.message;
          const info = { type: "success", message: msg };
          createNotification(info)();
          navigate("/applicationattachment");
        } else {
          const info = { type: "success", message: msg };
          createNotification(info)();
        }
      } else {
        var msg = response?.data?.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    } catch (error) {
      const msg = error?.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    }
  };

  //----------------------------------------ProfessionalAppliedFor
  useEffect(() => {
    getProfessionalApplied();
  }, []);

  function getProfessionalApplied() {
    var payload = {
      actionid: "Resources",
      ResourceType: "ProfessionalApplied",
      ResourceParam: "",
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response.data;
        setProfessionalAppliedData(data);
      });
  }
  const ProfessionalAppliedInputChange = (e) => {
    ProfessionalAppliedData?.map((obj) => {
      var ProfessionalID = obj?.ResourceID;
      if (e.target.value == ProfessionalID) {
        setProfessionalAppliedID(ProfessionalID);
      }
    });
  };

  //----------------------------------------CommissionerHO/HQ
  useEffect(() => {
    getCommissioner();
  }, []);

  function getCommissioner() {
    var payload = {
      actionid: "Resources",
      ResourceType: "AppliedFrom",
      ResourceParam: "",
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setCommissionerData(data);
      });
  }
  const CommissionerInputChange = (e) => {
    CommissionerData?.map((obj) => {
      var commissionerID = obj?.ResourceID;
      if (e.target.value == commissionerID) {
        setCommissioner(commissionerID);
      }
    });
  };

  //----------------------------------------JobTittle(Kozi)
  useEffect(() => {
    getJobTittle();
  }, []);

  function getJobTittle() {
    var payload = {
      actionid: "Resources",
      ResourceType: "kozi",
      ResourceParam: "",
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setKoziData(data);
      });
  }
  const KoziInputChange = (e) => {
    KoziData?.map((obj) => {
      var KoziCode = obj?.ResourceID;
      if (e.target.value == KoziCode) {
        setKoziCode(KoziCode);
      }
    });
  };

  //----------------------------------------EntryCreteria
  useEffect(() => {
    getEntryCreteria();
  }, []);

  function getEntryCreteria() {
    var payload = {
      actionid: "Resources",
      ResourceType: "EntryCreteria",
      ResourceParam: "",
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response.data;
        setEntryCreteriaData(data);
      });
  }
  const EntryCreteriaInputChange = (e) => {
    EntryCreteriaData?.map((obj) => {
      var entryCreteriaId = obj?.ResourceID;
      if (e.target.value == entryCreteriaId) {
        setEntryCretariaID(entryCreteriaId);
      }
    });
  };

  //----------------------------------------territory
  useEffect(() => {
    getterritories();
  }, []);

  function getterritories() {
    var payload = {
      actionid: "Resources",
      ResourceType: "Territory",
      ResourceParam: "",
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setTerritoryData(data);
      });
  }
  const TerritoryInputChange = (e) => {
    TerritoryData?.map((obj) => {
      var territoryId = obj?.ResourceID;
      if (e.target.value == territoryId) {
        setTerritoryID(territoryId);
        getRegionHandler(territoryId);
      }
    });
  };

  //----------------------------------------Region
  function getRegionHandler(territoryId) {
    var payload = {
      actionid: "Resources",
      ResourceType: "RegionByTerritory",
      ResourceParam: territoryId,
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setRegionData(data);
      });
  }
  const RegionInputChange = (e) => {
    RegionData?.map((obj) => {
      var RegionID = obj?.ResourceID;
      if (e.target.value == RegionID) {
        setRegionByTerritory(RegionID);
        getDistrictHandler(RegionID);
      }
    });
  };

  //----------------------------------------District
  function getDistrictHandler(RegionID) {
    var payload = {
      actionid: "Resources",
      ResourceType: "DistrictByRegion",
      ResourceParam: RegionID,
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setDistrictData(data);
      });
  }
  const DistrictInputChange = (e) => {
    DistrictData?.map((obj) => {
      var DistrictID = obj?.ResourceID;
      if (e.target.value == DistrictID) {
        setDistrictByRegion(DistrictID);
        getWardHandler(DistrictID);
      }
    });
  };

  //----------------------------------------Ward
  function getWardHandler(DistrictID) {
    var payload = {
      actionid: "Resources",
      ResourceType: "WardByDistrict",
      ResourceParam: DistrictID,
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setWardData(data);
      });
  }

  const WardInputChange = (e) => {
    WardData?.map((obj) => {
      var WardByDistrictID = obj?.ResourceID;
      if (e.target.value == WardByDistrictID) {
        setWardID(WardByDistrictID);
      }
    });
  };

  return (
    <>
      <Header />
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 module-content">
            <div class="col-sm-12">
              <NavigationSection />
              <Tabs defaultIndex={0}>
                <TabList>
                  <Tab style={{ color: "blue" }}>Application Information</Tab>
                  <Tab disabled>Application Attachments</Tab>
                  <Tab disabled>Applicant Declaration</Tab>
                </TabList>
                <TabPanel></TabPanel>
                <TabPanel></TabPanel>
                <TabPanel></TabPanel>
              </Tabs>
              <div
                className="col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-registration-tab"
                style={{ marginTop: "1px", paddingRight: "1px" }}
              >
                <form id="step-one-form" onSubmit={handleSubmit}>
                  <div className="box box-default">
                    <br />
                    <div className="row">
                      <div className="col-md-3">
                        <label
                          className="control-label"
                          for="passportpersonalandcitizenship-maritalstatusid"
                        >
                          Apply to CGI/CIZ
                        </label>
                        <select
                          id="commissionerID"
                          name="Commissioner"
                          className="form-control text-uppercase"
                          value={Commissioner}
                          onChange={(e) => CommissionerInputChange(e)}
                          required
                        >
                          <option>Select HO/HQ..</option>
                          {CommissionerData?.map((obj) => {
                            return (
                              <option
                                key={obj?.ResourceID}
                                value={obj?.ResourceID}
                              >
                                {obj?.ResourceName}
                              </option>
                            );
                          })}
                        </select>
                        <p className="help-block help-block-error"></p>
                      </div>

                      <div className="col-md-3">
                        <label
                          className="control-label"
                          for="passportpersonalandcitizenship-maritalstatusid"
                        >
                          Employment post
                        </label>
                        <select
                          id="KoziCode"
                          name="kozi"
                          className="form-control text-uppercase"
                          value={KoziCode}
                          onChange={(e) => KoziInputChange(e)}
                          required
                        >
                          <option>Select..</option>
                          {KoziData?.map((obj) => {
                            return (
                              <option
                                key={obj?.ResourceID}
                                value={obj?.ResourceID}
                              >
                                {obj?.ResourceName}
                              </option>
                            );
                          })}
                        </select>
                        <p className="help-block help-block-error"></p>
                      </div>

                      <div className="col-md-3">
                        <label
                          className="control-label"
                          for="passportpersonalandcitizenship-maritalstatusid"
                        >
                          Education level apply for:
                        </label>
                        <select
                          id="entryCreteriaId"
                          name="EntryCretariaID"
                          className="form-control text-uppercase"
                          value={EntryCretariaID}
                          onChange={(e) => EntryCreteriaInputChange(e)}
                          required
                        >
                          <option>Select HO/HQ..</option>
                          {EntryCreteriaData?.map((obj) => {
                            return (
                              <option
                                key={obj?.ResourceID}
                                value={obj?.ResourceID}
                              >
                                {obj?.ResourceName}
                              </option>
                            );
                          })}
                        </select>
                        <p className="help-block help-block-error"></p>
                      </div>

                      <div className="col-md-3">
                        <label
                          className="control-label"
                          for="passportpersonalandcitizenship-maritalstatusid"
                        >
                          Professional applied for:
                        </label>
                        <select
                          id="ProfessionalID"
                          name="ProfessionalAppliedID"
                          className="form-control text-uppercase"
                          value={ProfessionalAppliedID}
                          onChange={(e) => ProfessionalAppliedInputChange(e)}
                          required
                        >
                          <option>Select..</option>
                          {ProfessionalAppliedData?.map((obj) => {
                            return (
                              <option
                                key={obj?.ResourceID}
                                value={obj?.ResourceID}
                              >
                                {obj?.ResourceName}
                              </option>
                            );
                          })}
                        </select>
                        <p className="help-block help-block-error"></p>
                      </div>
                    </div>
                    <br />

                    <div className="box-header with-border">
                      <h3 className="box-title">Current Residence</h3>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group field-field-checkNo. required">
                          <label
                            className="control-label"
                            for="passportpersonalandcitizenship-occupation"
                          >
                            Residence
                          </label>
                          <select
                            id="TerritoryID"
                            name="TerritoryID"
                            className="form-control text-uppercase"
                            value={TerritoryID}
                            onChange={(e) => TerritoryInputChange(e)}
                            required
                          >
                            <option>Select ..</option>
                            {TerritoryData?.map((obj) => {
                              return (
                                <option
                                  key={obj?.ResourceID}
                                  value={obj?.ResourceID}
                                >
                                  {obj?.ResourceName}
                                </option>
                              );
                            })}
                          </select>
                          <p className="help-block help-block-error"></p>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="form-group field-field-checkNo. required">
                          <label
                            className="control-label"
                            for="passportpersonalandcitizenship-occupation"
                          >
                            Region
                          </label>
                          <select
                            id="RegionID"
                            name="RegionByTerritory"
                            className="form-control text-uppercase"
                            value={RegionByTerritory}
                            onChange={(e) => RegionInputChange(e)}
                            required
                          >
                            <option>Select..</option>
                            {RegionData?.map((obj) => {
                              return (
                                <option
                                  key={obj?.ResourceID}
                                  value={obj?.ResourceID}
                                >
                                  {obj?.ResourceName}
                                </option>
                              );
                            })}
                          </select>
                          <p className="help-block help-block-error"></p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group field-field-checkNo. required">
                          <label
                            className="control-label"
                            for="passportpersonalandcitizenship-occupation"
                          >
                            District
                          </label>
                          <select
                            id="DistrictID"
                            name="DistrictByRegion"
                            className="form-control text-uppercase"
                            onChange={(e) => DistrictInputChange(e)}
                            value={DistrictByRegion}
                            required
                          >
                            <option>Select..</option>
                            {DistrictData?.map((obj) => {
                              return (
                                <option
                                  key={obj?.ResourceID}
                                  value={obj?.ResourceID}
                                >
                                  {obj?.ResourceName}
                                </option>
                              );
                            })}
                          </select>
                          <p className="help-block help-block-error"></p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group field-field-checkNo. required">
                          <label
                            className="control-label"
                            for="passportpersonalandcitizenship-occupation"
                          >
                            Ward/Shehia
                          </label>
                          <select
                            id="WardByDistrictID"
                            name="WardID"
                            className="form-control text-uppercase"
                            onChange={(e) => WardInputChange(e)}
                            value={WardID}
                            required
                          >
                            <option>Select..</option>
                            {WardData?.map((obj) => {
                              return (
                                <option
                                  key={obj?.ResourceID}
                                  value={obj?.ResourceID}
                                >
                                  {obj?.ResourceName}
                                </option>
                              );
                            })}
                          </select>
                          <p className="help-block help-block-error"></p>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group field-field-checkNo. required">
                          <label
                            className="control-label"
                            for="village/street"
                          >
                            Village/Street
                          </label>
                          <input
                            type="text"
                            id=""
                            className="form-control text-uppercase"
                            name="StreetName"
                            onChange={(e) => setStreetName(e.target.value)}
                            value={StreetName}
                            required
                          />
                          <p className="help-block help-block-error"></p>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <label
                          className="control-label"
                          for="passportpersonalandcitizenship-maritalstatusid"
                        >
                          Disability
                        </label>

                        <select
                          value={Disability}
                          onChange={(e) => setDisability(e.target.value)}
                          id="DisabilityID"
                          name="Disability"
                          className="form-control"
                          required
                        >
                          <option value="">Disability?</option>
                          <option value="0">NO</option>
                          <option value="1">YES</option>
                        </select>
                        <p className="help-block help-block-error"></p>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group field-field-checkNo. required">
                          <label
                            className="control-label"
                            for="passportpersonalandcitizenship-occupation"
                          >
                            Address
                          </label>
                          <textarea
                            type="text"
                            style={{ textTransform: "uppercase" }}
                            id="PoBoxID"
                            className="form-control"
                            name="PoBox"
                            onChange={(e) => setPoBox(e.target.value)}
                            value={PoBox}
                            required
                          />
                          <p className="help-block help-block-error"></p>
                        </div>
                      </div>
                    </div>

                    <div className="box-footer">
                      <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                          <button
                            className="btn btn-default btn-block btn-label-next"
                            type="submit"
                          >
                            Next | <i className="fa fa-angle-double-right"></i>{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default NewAppInfo;
