import React, { useState } from "react";
import axios from "axios";
import "../includes/css/template.css";
import "../includes/assets/5aa1fa4e/css/font-awesome.min.css";
import { useIdleTimer } from "react-idle-timer";
import "../includes/assets/40b83da1/css/bootstrap.css";
import "../includes/css/loginCopy.css";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import { createNotification } from "./Popup";
import {
  remSessionLogout,
  removeUserInfo,
  getUserInfo,
  url,
  getAjiraToken,
} from "../utility/Common";

function Header() {
  const navigate = useNavigate();
  var md5 = require("md5");
  const [errors, setErrors] = useState({});
  const [showResetPassword, setShowResetPassword] = useState(false);
  const handleCloseResetPassword = () => setShowResetPassword(false);
  const handleShowResetPassword = () => setShowResetPassword(true);
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [OldPassword, setOldPassword] = useState("");
  const [UsernameReset, setUsernameReset] = useState("");
  const [callBack, setcallBack] = useState(false);

  const checkResetPasswordPattern = () => {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordPattern?.test(NewPassword);
  };

  const handleLogout = () => {
    removeUserInfo();
    remSessionLogout();
    navigate("/");
  };
  
  //auto session logout
  const handleOnIdle = (event) => {
    // alert("This session has already been expired. Please login again.");
    handleLogout();
  };
  const handleOnActive = (event) => {
    // console.log('user is active', event)
  };
  const handleOnAction = (event) => {
    // console.log('user did something', event)
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  <script type="text/javascript">
    function preventBack() {window.history.forward()}
    setTimeout(preventBack, 0) window.onunload = function () {null}
  </script>;

  //-------------------------------------------ResetPassword
  const ResetPassword = {
    SystemId: "UHM005",
    ResourceType: "ResetApplicant",
    ManagementDataType: "ProfileManagement",
    UsernameReset,
    OldPassword: md5(OldPassword),
    NewPassword: md5(NewPassword),
    AccessorId: getUserInfo()?.ApplicantID,
  };

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    const token = getAjiraToken();
    if (NewPassword !== ConfirmPassword) {
      return setPasswordMatchError("Passwords do not match");
    }
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        ResetPassword,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = response?.data;
      const code = data?.code;
      if (code === 1) {
        navigate("/");
      }
    } catch (error) {
      const msg = error?.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    }
  };

  return (
    <>
      <div class="logoBar">
        <div class="container">
          <div class="row">
            <div class="col-md-1 col-sm-1 col-xs-1 vertical-align text-left hidden-xs"></div>
            <div class="col-md-10 col-sm-10 col-xs-12 vertical-align text-center">
              <h3
                class="m-b-1"
                style={{ fontSize: "2em", fontFamily: "Yu Gothic Light" }}
              >
                Tanzania Immigration Services Department
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="logoBar2">
        <div class="container">
          <div class="row">
            <div class="col-md-1 col-sm-1 col-xs-1 vertical-align text-left hidden-xs"></div>
            <div class="col-md-10 col-sm-10 col-xs-12 vertical-align text-center">
              <h3
                class="m-b-1"
                style={{ fontSize: "3em", fontFamily: "Yu Gothic" }}
              >
                RECRUITMENT PORTAL
              </h3>
            </div>
          </div>
        </div>
      </div>
      <ul
        class="ul2"
        style={{
          color: "white",
          class: "btn",
        }}
      >
        <li class="li2">
          <Link to="/home">
            <b>
              <i class="fa fa-home" aria-hidden="true"></i>
            </b>
          </Link>
        </li>
        <li class="li2" style={{ float: "right" }}>
          <button onClick={handleShowResetPassword} class="active">
            <b>&nbsp;&nbsp;{getUserInfo()?.FullName}&nbsp;&nbsp;</b>
          </button>

          <button onClick={() => handleLogout()} class="active">
            <b>
              <i class="fa fa-sign-out" aria-hidden="true"></i>
              &nbsp;Logout&nbsp;&nbsp;
            </b>
          </button>
        </li>
      </ul>

      {/*-----------------------------------------------ResetPassword-*/}
      <Modal show={showResetPassword} onHide={handleCloseResetPassword}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitPassword}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="20010547641550000123"
                value={UsernameReset}
                onChange={(e) => setUsernameReset(e.target.value)}
                maxLength={20}
                minLength={20}
                title="NIN Format: 20010547641550000123"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Old Password"
                value={OldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="New Password"
                value={NewPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />

              {errors.password && (
                <span className="text-danger">{errors.password}</span>
              )}

              {NewPassword && !checkResetPasswordPattern() && (
                <p style={{ color: "red" }}>
                  Password must be at least 8 characters long and include at
                  least one uppercase letter, one lowercase letter, one number,
                  and one special character.
                </p>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Confirm Passowrd</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                value={ConfirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>
            {passwordMatchError && (
              <div style={{ color: "red" }} className="error-message">
                {passwordMatchError}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseResetPassword}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default Header;
