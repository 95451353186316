import React from "react";
import axios from "axios";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { logo } from "./utility";
import QRCode from "qrcode";
import { Link } from "react-router-dom";
import { createNotification } from "../Popup";
import { url, getUserInfo, getAjiraToken } from "../../utility/Common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PdfFromDocument = () => {
  const fetchData = async () => {
    const applicationID = getUserInfo().ApplicationID;
    const token = getAjiraToken();
    try {
      const response = await axios.post(
        url() + "erms_applicationmanagement",
        {
          SystemId: "UHM005",
          ManagementDataType: "ApplicantAttendance",
          ResourceType: "DownloadTicket",
          ApplicationID: applicationID,
          AccessorId: getUserInfo()?.ApplicantID,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.code == 1) {
        const data = response?.data?.data[0];
        generatePDF(data);
      }
    } catch (error) {
      const msg = error?.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
      console.error(error);
    }
  };

  const generatePDF = async (data) => {
    if (!data) {
      alert("Data is not yet loaded!");
      return;
    }

    const qrCodeDataUrl = await QRCode.toDataURL(data?.ApplicationNO);

    const docDefinition = {
      content: [
        {
          columns: [
            {
              width: 10,
              text: "",
            },
            {
              text: [
                {
                  text: "JAMHURI YA MUUNGANO WA TANZANIA\n",
                  style: "title",
                  fontSize: 12,
                },
                {
                  text: "WIZARA YA MAMBO YA NDANI YA NCHI\n",
                  style: "subtitle",
                  fontSize: 9,
                },
                {
                  text: "IDARA YA UHAMIAJI\n",
                  style: "subtitle",
                  fontSize: 9,
                  bold: true,
                },
              ],
              alignment: "center",
            },
            {
              width: 10,
              text: "",
            },
          ],
        },

        {
          columns: [
            {
              text: "ANUANI YA SIMU:  “UHAMIAJI” \n TELEFAX:  +255 22 2850595, +255 22 2850584 \n SIMU:  +255 22 2850575/6",
              fontSize: 9,
              lineHeight: 1.5,
              alignment: "left",
              margin: [0, 10, 0, 0],
            },
            {
              image: "data:image/jpeg;base64," + logo,
              width: 60,
              height: 60,
              alignment: "center",
              margin: [0, -5, 0, 0],
            },
            {
              text: "OFISI YA KAMISHNA JENERALI \n UHAMIAJI MAKAO MAKUU. \n S. L. P. 1181, DODOMA \n",
              fontSize: 9,
              lineHeight: 1.5,
              alignment: "left",
              margin: [100, 10, 0, 0],
            },
          ],
          margin: [0, 10, 0, 10],
        },

        {
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: -20,
              x2: 515,
              y2: -20,
              lineWidth: 0.5,
              color: "grey",
            },
          ],
          margin: [0, 10, 0, 0],
        },

        {
          text: "CALL FOR INTERVIEW",
          style: "header",
          alignment: "center",
          fontSize: 12,
          margin: [0, -5, 0, 0],
        },

        {
          style: "tableExample",
          fontSize: 11,
          margin: [0, 3, 0, 0],
          table: {
            widths: ["*", 60],
            body: [
              [
                {
                  text: [
                    "Application No. ",
                    { text: data?.ApplicationNO, bold: true },
                  ],
                },
                {
                  image: qrCodeDataUrl,
                  width: 60,
                  height: 60,
                  alignment: "center",
                  margin: [0, 0, 0, 0],
                  rowSpan: 3,
                },
              ],
              [
                {
                  text: [
                    "Application Post: ",
                    { text: data?.ApplicationPost, bold: true },
                    {},
                  ],
                },
              ],
              [
                {
                  text: [
                    "Date for Interview: ",
                    { text: data?.DateforInterview, bold: true },
                    {},
                  ],
                },
              ],
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              return i == 0 || i == node.table.body.length ? 0.5 : 0.5;
            },
            vLineWidth: function (i, node) {
              return i == 0 || i == node.table.widths.length ? 0.5 : 0.5;
            },
            hLineColor: function (i, node) {
              return i == 0 || i == node.table.body.length ? "grey" : "grey";
            },
            vLineColor: function (i, node) {
              return i == 0 || i == node.table.widths.length
                ? "grey"
                : "grey";
            },
            paddingLeft: function (i, node) {
              return 4;
            },
            paddingRight: function (i, node) {
              return 4;
            },
            paddingTop: function (i, node) {
              return 4;
            },
            paddingBottom: function (i, node) {
              return 4;
            },
          },
        },

        {
          text: "APPLICANT INFORMATION",
          style: "header",
          alignment: "left",
          fontSize: 12,
          margin: [0, 13, 0, 0],
        },

        {
          style: "tableExample",
          fontSize: 11,
          margin: [0, 3, 0, 0],
          table: {
            widths: [60, "*", 100],
            body: [
              [
                {
                  image: "data:image/jpeg;base64," + data?.PhotoPath,
                  width: 60,
                  height: 60,
                  alignment: "center",
                  margin: [0, 0, 0, 0],
                  rowSpan: 3,
                },
                {
                  text: ["Full name: ", { text: data?.FullName, bold: true }],
                },
                {
                  text: ["Age: ", { text: "39", bold: true }],
                },
              ],
              [
                {},
                {
                  text: [
                    "Education level: ",
                    { text: data?.EducationLevel, bold: true },
                  ],
                },
                {
                  text: ["Gender: ", { text: data?.Gender, bold: true }],
                },
              ],
              [
                {},
                {
                  text: [
                    "Professional: ",
                    { text: data?.ProfessionalAppliedName, bold: true },
                  ],
                },
                {},
              ],
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              return i == 0 || i == node.table.body.length ? 0.5 : 0.5;
            },
            vLineWidth: function (i, node) {
              return i == node.table.widths.length
                ? 0
                : 0.5 || i == node.table.widths.length
                ? 0.5
                : 0;
            },
            hLineColor: function (i, node) {
              return i == 0 || i == node.table.body.length ? "grey" : "grey";
            },
            vLineColor: function (i, node) {
              return i == 0 || i == node.table.widths.length
                ? "grey"
                : "grey";
            },
            paddingLeft: function (i, node) {
              return 4;
            },
            paddingRight: function (i, node) {
              return 4;
            },
            paddingTop: function (i, node) {
              return 4;
            },
            paddingBottom: function (i, node) {
              return 4;
            },
          },
        },
      ],
    };
    pdfMake.createPdf(docDefinition).download("call-for-interview.pdf");
  };

  return (
    <div>
      <Link onClick={fetchData}>
        Download <FontAwesomeIcon icon={faFilePdf} />{" "}
      </Link>
      ticket for Interview
    </div>
  );
};

export default PdfFromDocument;
