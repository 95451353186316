import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Home from "./components/Home";
import Personal_Info from "./components/profile/Personal_Info";
import EducationBackg from "./components/profile/EducationBackg";
import Professionals from "./components/profile/Professionals";
import NewApplication from "./components/apply/NewApplication";
import UpdateNewApplication from "./components/apply/update/UpdateNewApplication";
import ApplicationAttachment from "./components/apply/ApplicationAttachment";
import Status from "./components/apply/Status";
import Professional_Upload from "./components/profile/Professional_Upload";
import Submition from "./components/profile/Submition";
import Submit_Appl from "./components/apply/Submit_Appl";
import Declaration from "./components/apply/Declaration";
import { NotificationContainer } from 'react-notifications';
import { useEffect } from "react";

function App() {

  useEffect(() => {
    window.scrollTo(0, 1);
    const handleResize = () => {
      window.scrollTo(0, 1);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);  

  return (
    <div className="App">
        <NotificationContainer />
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/personalinfo" element={<Personal_Info />} />
          <Route exact path="/educationbackg" element={<EducationBackg />} />
          <Route exact path="/professionals" element={<Professionals />} />
          <Route exact path="/newapplication" element={<NewApplication />} />
          <Route exact path="/updatenewapplication" element={<UpdateNewApplication />} />
          <Route exact path="/applicationattachment" element={<ApplicationAttachment />} />
          <Route exact path="/status" element={<Status />} />
          <Route exact path="/professionalupload" element={<Professional_Upload />} />
          <Route exact path="/submition" element={<Submition />} />
          <Route exact path="/submitappl" element={<Submit_Appl />} />
          <Route exact path="/declaration" element={<Declaration />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
