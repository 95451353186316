import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";

export const createNotification = (info) => {
  const type = info.type;
  const message = info.message;
  const timeOut = info.timeOut || 6000; // Default: 6000 milliseconds (6 seconds)

  return () => {
    switch (type) {
      case "info":
        NotificationManager?.info(message, null, timeOut);
        break;
      case "success":
        NotificationManager?.success(message, null, timeOut);
        break;
      case "warning":
        NotificationManager?.warning(message, null, timeOut);
        break;
      case "error":
        NotificationManager?.error(message, null, timeOut);
        break;
      default:
        break;
    }
  };
};
