import React, { useState} from "react";
import axios from "axios";
import Header from "../Header";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../../includes/myStyles.css";
import NavigationSection from "./NavigationSection";

function Apply() {
  const navigate = useNavigate();

  // const [fields, setFields] = useState({});
  // function handleChange(event) {
  //   const target = event.target;
  //   const value = target.type == "checkbox" ? target.checked : target.value;
  //   const name = target.name;
  //   setFields({ ...fields, [name]: value });
  // }

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/personalinf");
  };

  return (
    <>
      <Header />
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 module-content">
            <div class="col-sm-12">
              <NavigationSection />
              <div className="row">
                <p className="help-block help-block-error"></p>
                <form id="passport-application-form" onSubmit={handleSubmit}>
                  <input type="hidden" name="_csrf" value="" />

                  <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-4">
                      <div className="form-group field-field-checkNo. required">
                        <label
                          className="control-label"
                          for="passportpersonalandcitizenship-occupation"
                        >
                          Namba ya NIDA
                        </label>
                        <input
                          type="text"
                          style={{ textTransform: "uppercase" }}
                          id="Occupation"
                          className="form-control"
                          name="Occupation"
                          required
                        />
                        <p className="help-block help-block-error"></p>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group field-field-checkNo. required">
                        <label
                          className="control-label"
                          for="passportpersonalandcitizenship-occupation"
                        >
                          Namba ya Simu
                        </label>
                        <input
                          type="text"
                          id="Occupation"
                          className="form-control"
                          name="Occupation"
                          required
                        />
                        <p className="help-block help-block-error"></p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-4">
                      <button className="btn btn-default btn-label-next pull-right">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Apply;
