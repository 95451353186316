import Header from "./Header";
import NavigationSection from "./apply/NavigationSection";
import React from "react";
import pdf from "../includes/documents/User_Manual_For_Aplicant.pdf";
import { Link } from "react-router-dom";

function Home() {
  const openPDF = () => {
    window.open(pdf, "_blank");
  };

  return (
    <>
      <Header />
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 module-content">
            <div class="col-sm-12">
              <NavigationSection />

              <div class="row">
                <div class="col-sm-5">
                  <div className="page-header fw-bold text-primary">
                    Welcome to Tanzania Immigration Recruitment portal:
                  </div>
                  <div>
                    You can now apply for the post of Immigration Constable
                  </div>

                  <br />

                  <Link to onClick={openPDF}>
                    <div className="fw-bold">
                      How to use Recruitment Portal Application{" "}
                      <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                    </div>{" "}
                  </Link>
                </div>
                <div class="col-sm-1"></div>
                <div class="col-sm-6">
                  <div className="page-header fw-bold text-primary">
                    The following documents shall be required to support your
                    application:
                  </div>
                  <ol>
                    <li>
                      Barua ya Utambulisho toka Serikali ya Mtaa/Kijiji au
                      Shehia.
                    </li>
                    <li>
                      Kwa waombaji waliopo Makambini, barua zao zipitie kwa
                      Wakuu wa Kambi husika.
                    </li>
                    <li>Nakala ya Cheti cha Kuzaliwa.</li>
                    <li>
                      Namba au Kitambulisho cha Taifa (NIDA) au Kitambulisho cha
                      Mzanzibari.
                    </li>
                    <li>Vyeti vya kuhitimu shule (Leaving Certificates).</li>
                    <li>Vyeti vya Taaluma (Academic Certificates).</li>
                    <li>Vyeti vingine vya Fani mbalimbali.</li>
                    <li>Wasifu wa Mwombaji (CV).</li>
                    <li>Picha Moja ya Pasipoti zenye rangi ya Bluu.</li>
                    <li>
                      Muombaji anatakiwa ku-scan nyaraka zote pamoja na barua ya
                      maombi ya kazi, kila nyaraka isizidi ukubwa wa 500Kb.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Home;
