import { React, useEffect, useState } from "react";
import Header from "../Header";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import {
  url,
  getUserInfo,
  urlResource,
  getAjiraToken,
} from "../../utility/Common";
import { Button, Form, Modal } from "react-bootstrap";
import { createNotification } from "../Popup";

function Professionals() {
  const navigate = useNavigate();
  const [ApplicantID, setApplicantID] = useState("");
  const [Data, setData] = useState([]);
  const [SpecialisedData, setSpecialisedData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [updatePop, setUpdatePop] = useState(false);
  const handleCloseUpdate = () => setUpdatePop(false);
  const handleShowUpdate = () => setUpdatePop(true);

  var md5 = require("md5");
  const [showResetPassword, setShowResetPassword] = useState(false);
  const handleCloseResetPassword = () => setShowResetPassword(false);
  const handleShowResetPassword = () => setShowResetPassword(true);
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [OldPassword, setOldPassword] = useState("");
  const [UsernameReset, setUsernameReset] = useState("");
  const [errors, setErrors] = useState({});

  const [IndexNo, setIndexNo] = useState("");
  const [ProfessionalID, setProfessionalID] = useState("");
  const [CourseName, setCourseName] = useState("");
  const [CompletedYear, setCompletedYear] = useState("");
  const [InstitutionName, setInstitutionName] = useState("");
  const [AttachmentPath, setAttachmentPath] = useState("");
  const [ProffessionalName, setProffessionalName] = useState("");
  const [SpecializedID, setSpecializedID] = useState("");
  const [callBack, setcallBack] = useState(false);
  const [DocID, setDocID] = useState();
  const [EducationID, setEducationID] = useState();
  const [GradeID, setGradeID] = useState();
  const [EducationLevelID, setEducationLevelID] = useState();

  //----------------------------------------ViewProfessionalTable
  useEffect(() => {
    const fetchProfessionalData = async () => {
      try {
        const AppID = getUserInfo()?.ApplicantID;
        setApplicantID(AppID);

        const Proffesionals_Table = {
          SystemId: "UHM005",
          ManagementDataType: "ViewEducationalProfessional",
          ResourceType: "SELECTPRO",
          ApplicantID: AppID,
          AccessorId: getUserInfo()?.ApplicantID,
        };

        const token = getAjiraToken();
        const response = await axios.post(
          url() + "erms_applicationregistration",
          Proffesionals_Table,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const code = response?.data?.code;
        if (code == 1) {
          const data = response?.data;
          setData(data?.data);
        }
      } catch (error) {
        const msg = error?.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    };
    fetchProfessionalData();
    return () => {};
  }, [callBack]);

  //----------------------------------------VewUpdate_Professional
  function setUpdateProfessionalData(rowUpdate) {
    handleShowUpdate(true);
    setIndexNo(rowUpdate.IndexNo);
    setProfessionalID(rowUpdate.SpecializedID);
    setCourseName(rowUpdate.CourseName);
    setCompletedYear(rowUpdate.CompletedYear);
    setInstitutionName(rowUpdate.InstitutionName);
    setAttachmentPath(rowUpdate.AttachmentPath);
    setEducationID(rowUpdate.EducationID);
    setGradeID(rowUpdate.GradeID);
    setEducationLevelID(rowUpdate.EducationLevelID);
    setProfessionalID(rowUpdate.ProfessionalID);
    setProffessionalName(rowUpdate.ProffessionalName);
    setDocID(rowUpdate.tDocID);
  }

  //----------------------------------------updatesProfessionalSubmit
  const UpdatesProfessional = {
    SystemId: "UHM005",
    ManagementDataType: "Educational_Backg",
    ResourceType: "UPDATE",
    ApplicantID: getUserInfo()?.ApplicantID,
    IndexNo,
    DocID: EducationID,
    CourseName: SpecializedID,
    CompletedYear,
    InstitutionName,
    AttachmentPath,
    GradeID,
    EducationLevelID: EducationLevelID,
    ProfessionalID,
    AccessorId: getUserInfo()?.ApplicantID,
  };

  const handleSubmitUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const token = getAjiraToken();
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        UpdatesProfessional,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const code = response?.data?.code;
      if (code == 1) {
        handleCloseUpdate();
        setIsLoading(false);
        setcallBack(callBack + 1);
        var msg = response?.data?.message;
        const info = { type: "success", message: msg };
        createNotification(info)();
      } else {
        setIsLoading(false);
        var msg = response?.data?.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  //----------------------------------------getSpecialised
  useEffect(() => {
    getSpecialised();
  }, []);

  function getSpecialised() {
    var payload = {
      actionid: "Resources",
      ResourceType: "Professional",
      ResourceParam: "",
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setSpecialisedData(data);
      });
  }

  const SpecialisedInputChange = (e) => {
    var data = e.target.value;
    if (data) {
      setProfessionalID(data);
    }
  };

  //----------------------------------------getYear
  const handleCompletedYear = (e) => {
    setCompletedYear(e.target.value);
  };

  const startYear = 2000;
  const endYear = new Date().getFullYear();
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index
  );

  //----------------------------------------Base64_PDF
  const handleFileChanges = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.type !== "application/pdf") {
        setErrorMessage("Invalid file type. Please upload a file in PDF format.");
        return;
      }
      if (selectedFile.size > 500000) {
        setErrorMessage(
          "The file size is too large. Please upload a file smaller than 500KB."
        );
        return;
      }
      setErrorMessage("");
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        let base64File = reader.result;
        base64File.toString();
        setAttachmentPath(base64File);
      };
    }
  };

  const checkResetPasswordPattern = () => {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordPattern?.test(NewPassword);
  };
  
  //-------------------------------------------ResetPassword
  const ResetPassword = {
    SystemId: "UHM005",
    ResourceType: "ResetApplicant",
    ManagementDataType: "ProfileManagement",
    UsernameReset,
    OldPassword: md5(OldPassword),
    NewPassword: md5(NewPassword),
    AccessorId: getUserInfo()?.ApplicantID,
  };

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    const token = getAjiraToken();

    if (NewPassword !== ConfirmPassword) {
      return setPasswordMatchError("Passwords do not match");
    }
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        ResetPassword,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = response?.data;
      const code = data?.code;
      if (code == 1) {
        navigate("/");
      }
    } catch (error) {
      const msg = error.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
      console.error(error);
    }
  };
  //-----------

  function handleSubmit(e) {
    navigate("/home");
  }

  return (
    <>
      <Header />
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 module-content">
            <br />
            <div class="col-sm-12">
              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 tab-registration-menu hidden-xs">
                <div className="list-group">
                  <Link
                    to="#"
                    className="list-group-item fw-light text-primary done"
                  >
                    Personal Information
                  </Link>
                  <Link
                    to="/educationbackg"
                    className="list-group-item fw-light text-primary done"
                  >
                    Education Background
                  </Link>
                  <Link
                    to="#"
                    className="list-group-item fw-light text-primary active"
                  >
                    Other Profession
                  </Link>
                  <Link
                    onClick={handleShowResetPassword}
                    className="list-group-item "
                  >
                    Change Passowrd
                  </Link>
                </div>
              </div>
              <div
                className="col-lg-10 col-md-10 col-sm-10 col-xs-10 tab-registration-tab"
                style={{ marginTop: "1px", paddingRight: "1px" }}
              >
                <div className="tab-registration-content active">
                  <div className="box box-default">
                    <div className="box-header with-border">
                      <h3 className="box-title">Other Profession</h3>
                    </div>
                    <div className="box-body">
                      <div className="row">
                        <div class="box-body" style={{ display: "block" }}>
                          <div class="row">
                            <div class="col-md-12">
                              <Link to="/professionalupload">
                                <button
                                  type="button"
                                  class="btn btn-info pull-right"
                                  value=""
                                >
                                  <i class="fa fa-plus"></i> Add Professional
                                  Info
                                </button>
                              </Link>
                              <br />
                              <table class="table table-striped custab">
                                <thead>
                                  <tr>
                                    <th style={{ width: "25%" }}>
                                      Specialization
                                    </th>
                                    <th style={{ width: "15%" }}>
                                      Registration No.
                                    </th>
                                    <th style={{ width: "25%" }}>
                                      Institution Name
                                    </th>
                                    <th style={{ width: "15%" }}>
                                      Completed Year
                                    </th>
                                    <th
                                      style={{ width: "20%" }}
                                      className="text-center"
                                    >
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Data?.map((item) => (
                                    <tr key={item.id}>
                                      <td className="text-uppercase align-middle">
                                        {item.ProffessionalName}
                                      </td>
                                      <td className="text-uppercase align-middle">
                                        {item.IndexNo}
                                      </td>
                                      <td className="text-uppercase align-middle">
                                        {item.InstitutionName}
                                      </td>
                                      <td className="text-uppercase align-middle">
                                        {item.CompletedYear}
                                      </td>
                                      <td className="text-center">
                                        <button
                                          className="btn text-danger"
                                          onClick={() =>
                                            setUpdateProfessionalData(item)
                                          }
                                        >
                                          <i className="fa fa-edit"></i> Edit
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="box-footer">
                    <div className="row">
                      <div className="col-md-4">
                        <Link
                          to="/home"
                          className="btn btn-labeled btn-block btn-default"
                        >
                          <i className="fa fa-home"></i> | Dashboard
                        </Link>
                      </div>
                      <div className="col-md-4"></div>
                      <div className="col-md-4">
                        <Link
                          className="btn btn-default btn-block btn-label-next"
                          to="/home"
                        >
                          Submit | <i className="fa fa-save"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*----------------------------------------- Update Professional */}
      <Modal size="lg" show={updatePop} onHide={handleCloseUpdate}>
        <Modal.Header closeButton>
          <Modal.Title>Update Proffesional Certifications</Modal.Title>
        </Modal.Header>
        <Form id="Update_Profession" onSubmit={handleSubmitUpdate}>
          <Modal.Body>
            <div className="box box-default">
              <div className="box-body">
                <div className="row">
                  <div class="box-body" style={{ display: "block" }}>
                    <div class="row">
                      <div class="col-md-12">
                        <br />
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group field-MembershipNo required">
                              Qualified Certificate Name
                              <input
                                type="text"
                                className="form-control text-uppercase"
                                name="CourseName"
                                placeholder="Cisco Certified Network Professional (CCNP)"
                                onChange={(e) => setCourseName(e.target.value)}
                                value={CourseName}
                                aria-required="true"
                              />
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group field-pfNo. required">
                              Institution Name
                              <input
                                type="text"
                                className="form-control text-uppercase"
                                name="InstitutionName"
                                onChange={(e) =>
                                  setInstitutionName(e.target.value)
                                }
                                value={InstitutionName}
                                aria-required="true"
                              />
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group field-MembershipNo required">
                              Registration No.
                              <input
                                type="text"
                                className="form-control text-uppercase"
                                placeholder="CSCO123456789"
                                name="IndexNo"
                                onChange={(e) => setIndexNo(e.target.value)}
                                value={IndexNo}
                                aria-required="true"
                              />
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group field-pfNo. required">
                              Specialised
                              <select
                                id="SpecialisedID"
                                className="form-control text-uppercase"
                                name="SpecialisedID"
                                value={ProfessionalID}
                                onChange={(e) => SpecialisedInputChange(e)}
                              >
                                <option>Select..</option>
                                {SpecialisedData?.map((obj) => {
                                  return (
                                    <option
                                      key={obj.ResourceID}
                                      value={obj.ResourceID}
                                    >
                                      {obj.ResourceName}
                                    </option>
                                  );
                                })}
                              </select>
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group field-pfNo. required">
                              Completed Year
                              <select
                                className="form-control text-uppercase"
                                name="EducationLevel"
                                value={CompletedYear}
                                onChange={(e) => handleCompletedYear(e)}
                                required
                              >
                                <option value="" disabled>
                                  Select..
                                </option>
                                {years?.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group field-MembershipNo required">
                              Attachment Certificate
                              <input
                                type="file"
                                className="form-control"
                                name="AttachmentPath"
                                onChange={handleFileChanges}
                                aria-required="true"
                              />
                              {errorMessage && (
                                <p
                                  style={{
                                    color: "red",
                                  }}
                                >
                                  {errorMessage}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseUpdate}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

       {/*-----------------------------------------------ResetPassword-*/}
       <Modal show={showResetPassword} onHide={handleCloseResetPassword}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitPassword}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="20010547641550000123"
                // autoFocus
                value={UsernameReset}
                onChange={(e) => setUsernameReset(e.target.value)}
                maxLength={20}
                minLength={20}
                title="NIN Format: 20010547641550000123"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Old Password"
                value={OldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="New Password"
                value={NewPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {errors.password && (
                <span className="text-danger">{errors.password}</span>
              )}

              {NewPassword && !checkResetPasswordPattern() && (
                <p style={{ color: "red" }}>
                  Password must be at least 8 characters long and include at
                  least one uppercase letter, one lowercase letter, one number,
                  and one special character.
                </p>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Confirm Passowrd</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                value={ConfirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>
            {passwordMatchError && (
              <div style={{ color: "red" }} className="error-message">
                {passwordMatchError}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseResetPassword}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
export default Professionals;
