import { React, useEffect, useState } from "react";
import Header from "../Header";
import { Link, useNavigate } from "react-router-dom";
import {
  url,
  urlResource,
  getUserInfo,
  setUserInfo,
  getAjiraToken,
} from "../../utility/Common";
import axios from "axios";
import { Button, Form, Modal } from "react-bootstrap";
import { createNotification } from "../Popup";

function EducationBackg_Table() {
  const navigate = useNavigate();
  const [ApplicantID, setApplicantID] = useState("");
  const [Data, setData] = useState([]);
  const [JKTDataTable, setJKTDataTable] = useState([]);
  const [callBack, setcallBack] = useState(false);
  const [callBack2, setcallBack2] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showEducationBackg, setShowEducationBackg] = useState(false);
  const handleCloseEducationBackg = () => setShowEducationBackg(false);
  const handleShowEducationBackg = () => setShowEducationBackg(true);

  const [isLoading, setIsLoading] = useState(false);
  const [InstitutionName, setInstitutionName] = useState("");
  const [IndexNo, setIndexNo] = useState("");
  const [ProfessionalID, setProfessionalID] = useState("");
  const [CourseName, setCourseName] = useState("");
  const [EducationID, setEducationID] = useState();
  const [DocID, setDocID] = useState();
  const [EducationLevel, setEducationLevel] = useState("");
  const [EducationLevelData, setEducationLevelData] = useState([]);
  const [PerformanceLevelData, setPerformanceLevelData] = useState([]);
  const [PerformanceLevel, setPerformanceLevel] = useState([]);
  const [GradeLevelData, setGradeLevelData] = useState([]);
  const [GradeLevel, setGradeLevel] = useState([]);
  const [SpecialisedData, setSpecialisedData] = useState([]);
  const [Specialised, setSpecialised] = useState("");
  const [CampID, setCampID] = useState("");

  const [updatePop, setUpdatePop] = useState(false);
  const handleCloseUpdate = () => setUpdatePop(false);
  // const handleShowUpdate = () => setUpdatePop(true);

  const [updateJKT, setUpdateJKT] = useState(false);
  const handleCloseJKTUpdate = () => setUpdateJKT(false);
  // const handleShowJKTUpdate = () => setUpdateJKT(true);

  const [ForceNumber, setForceNumber] = useState("");
  const [CompleteYear, setCompleteYear] = useState("");
  const [AttachmentPath, setAttachmentPath] = useState("");
  const [JKTData, setJKTData] = useState([]);
  const [CampCode, setCampCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [AppID, setAppID] = useState(null);
  const [currentData, setCurrentData] = useState();
  const [CompletedYear, setCompletedYear] = useState();
  // const [pdfUrl, setPdfUrl] = useState(null);
  var md5 = require("md5");
  const [errors, setErrors] = useState({});
  const [showResetPassword, setShowResetPassword] = useState(false);
  const handleCloseResetPassword = () => setShowResetPassword(false);
  const handleShowResetPassword = () => setShowResetPassword(true);
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [OldPassword, setOldPassword] = useState("");
  const [UsernameReset, setUsernameReset] = useState("");
  
  const [error, setError] = useState(null);

  const checkResetPasswordPattern = () => {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordPattern?.test(NewPassword);
  };

  //-------------------------------------------ResetPassword
  const ResetPassword = {
    SystemId: "UHM005",
    ResourceType: "ResetApplicant",
    ManagementDataType: "ProfileManagement",
    UsernameReset,
    OldPassword: md5(OldPassword),
    NewPassword: md5(NewPassword),
    AccessorId: getUserInfo()?.ApplicantID,
  };

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    const token = getAjiraToken();

    if (NewPassword !== ConfirmPassword) {
      return setPasswordMatchError("Passwords do not match");
    }
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        ResetPassword,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = response?.data;
      const code = data?.code;
      if (code == 1) {
        navigate("/");
      }
    } catch (error) {
      const msg = error?.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    }
  };
  //-----------

  // const [showCert, setShowCert] = useState(false);
  // const handleCloseCert = () => setShowCert(false);
  // const handleShowCert = () => {
  //   setShowCert(true);
  // };

  // function viwPdf(base64) {
  //   const decodedPdf = atob(base64);
  //   const pdfBlob = new Blob([decodedPdf], { type: "application/pdf" });
  //   const url = URL.createObjectURL(pdfBlob);
  //   setPdfUrl(url);
  //   setShowCert(() => true);
  // }

  //------------------------------------------------------------EducationBackg_Upload
  const EducationBackg_Upload = {
    SystemId: "UHM005",
    ManagementDataType: "Educational_Backg",
    ResourceType: "INSERT",
    ApplicantID: getUserInfo()?.ApplicantID,
    InstitutionName,
    IndexNo,
    EducationLevel,
    ProfessionalID,
    EducationLevelID: EducationLevel,
    GradeID: GradeLevel,
    CourseName,
    CompletedYear,
    AttachmentPath,
    AccessorId: getUserInfo()?.ApplicantID,
  };

  const handleSubmitEducationBackg = async (e) => {
    e.preventDefault();
    const token = getAjiraToken();
    const updatedEducationBackg_Upload = getBase64String(EducationBackg_Upload);
    console.log(token);
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        updatedEducationBackg_Upload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const data = response?.data;
      const code = data?.code;

      if (code == 1) {
        setcallBack2(code + 1);
        setUserInfo(response?.data.data[0]);
        var msg = response?.data.message;
        const info = { type: "success", message: msg };
        createNotification(info)();
        navigate("/educationbackg");
      } else {
        var msg = response?.data.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    } catch (error) {
      const msg = error.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    }
  };

  //----------------------------------------EducationBackgTableView
  useEffect(() => {
    const fetchEducationData = async () => {
      try {
        const AppID = getUserInfo()?.ApplicantID;
        setApplicantID(AppID);

        const EducationBackg_Table = {
          SystemId: "UHM005",
          ManagementDataType: "ViewEducationalBackground",
          ResourceType: "SELECT",
          ApplicantID: AppID,
          AccessorId: getUserInfo()?.ApplicantID,
        };

        const token = getAjiraToken();
        const response = await axios.post(
          url() + "erms_applicationregistration",
          EducationBackg_Table,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const code = response?.data?.code;
        if (code == 1) {
          const data = response?.data;
          if (data) {
            // var userInfo = response?.data?.data[0];
            setData(data?.data);
          } else {
            const info = { type: "info", message: "no data" };
            createNotification(info)();
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchEducationData();
    return () => {};
  }, [callBack2]);

  //----------------------------------------JKTTableView
  useEffect(() => {
    const fetchJKTData = async () => {
      try {
        const AppID = getUserInfo()?.ApplicantID;
        setApplicantID(AppID);

        const JKT_Table = {
          SystemId: "UHM005",
          ManagementDataType: "JKT",
          ResourceType: "ViewJKT",
          ApplicantID: AppID,
          AccessorId: getUserInfo()?.ApplicantID,
        };

        const token = getAjiraToken();
        const response = await axios.post(
          url() + "erms_applicationregistration",
          JKT_Table,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const code = response?.data?.code;
        if (code == 1) {
          const JKTDataTable = response?.data;
          setJKTDataTable(JKTDataTable?.data);
        }
      } catch (error) {
        const msg = error?.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    };

    fetchJKTData();
    return () => {};
  }, [callBack]);

  //----------------------------------------hide/show_DIV
  function toggleDiv() {
    var checkbox = document.getElementById("myCheckbox");
    var div = document.querySelector(".showhidediv");
    if (checkbox.checked) {
      div.style.display = "block";
    } else {
      div.style.display = "none";
    }
  }

  const handleCompletedYear = (e) => {
    setCompletedYear(e.target.value);
  };

  function handleSubmitProfile() {
    navigate("/home");
  }

  const handleCompleteYear = (e) => {
    setCompleteYear(e.target.value);
  };

  const startYear = 2000;
  const endYear = new Date().getFullYear();
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index
  );

  useEffect(() => {
    const userAppID = getUserInfo()?.ApplicantID;
    setAppID(userAppID);
  }, []);

  //------------------------------------------submitFunctionJKT
  const JKT_Upload = {
    SystemId: "UHM005",
    ManagementDataType: "JKT",
    ResourceType: "InsertJKT",
    ApplicantID: AppID,
    CompleteYear,
    ForceNumber,
    CampCode,
    AttachmentPath,
    AccessorId: getUserInfo()?.ApplicantID,
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const token = getAjiraToken();
    const updatedJKT_Upload = getBase64String(JKT_Upload);

    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        updatedJKT_Upload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const code = response?.data?.code;
      if (code == 1) {
        setIsLoading(false);
        setcallBack(code + 1);
        var msg = response?.data?.message;
        const info = { type: "success", message: msg };
        createNotification(info)();
        navigate("/educationbackg");
      } else {
        setIsLoading(false);
        var msg = response?.data?.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    } catch (error) {
      setIsLoading(false);
      const msg = error.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    }
  };

  //----------------------------------------VewUpdate_Education
  function setUpdateEducationData(rowUpdate) {
    setEducationLevel(rowUpdate.EducationLevelID);
    setInstitutionName(rowUpdate.InstitutionName);
    setIndexNo(rowUpdate.IndexNo);
    setProfessionalID(rowUpdate.SpecializedID);
    setSpecialised(rowUpdate.SpecializedID);
    setGradeLevel(rowUpdate.GradeID);
    setCourseName(rowUpdate.CourseName);
    setCompletedYear(rowUpdate.CompletedYear);
    setEducationID(rowUpdate.EducationID);
    setDocID(rowUpdate.DocID);
    setCurrentData(rowUpdate);
    setAttachmentPath(rowUpdate.AttachmentPath);
    setUpdatePop(true);
  }

  //----------------------------------------VewUpdate_JKT
  function setUpdateJKTData(rowUpdate) {
    setCampCode(rowUpdate.CampCode);
    setForceNumber(rowUpdate.ForceNumber);
    setCompleteYear(rowUpdate.CompleteYear);
    setAttachmentPath(rowUpdate.AttachmentPath);
    setCampID(rowUpdate.CampID);
    setUpdateJKT(true);
  }

  //----------------------------------------updatesEducationSubmit
  const UpdatesEducation = {
    SystemId: "UHM005",
    ManagementDataType: "Educational_Backg",
    ResourceType: "UPDATE",
    ApplicantID: getUserInfo()?.ApplicantID,
    InstitutionName,
    IndexNo,
    EducationLevel,
    ProfessionalID: Specialised,
    EducationLevelID: EducationLevel,
    GradeID: GradeLevel,
    CourseName,
    DocID: EducationID,
    CompletedYear,
    AttachmentPath,
    AccessorId: getUserInfo()?.ApplicantID,
  };

  const handleSubmitUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const token = getAjiraToken();
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        UpdatesEducation,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const code = response?.data.code;
      if (code == 1) {
        setIsLoading(false);
        setcallBack2(callBack + 1);
        var msg = response?.data?.message;
        const info = { type: "success", message: msg };
        createNotification(info)();
      } else {
        setIsLoading(false);
        var msg = response.data?.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    } catch (error) {
      setIsLoading(false);
      const msg = error?.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
      console.error(error);
    }
  };

  //----------------------------------------updatesJKTSubmit
  const updatesJKTSubmit = {
    SystemId: "UHM005",
    ManagementDataType: "JKT",
    ResourceType: "UpdateJKT",
    ApplicantID: AppID,
    ForceNumber,
    CampCode,
    CampID: CampID,
    CompleteYear,
    AttachmentPath,
    AccessorId: getUserInfo()?.ApplicantID,
  };
  const handleSubmitJKTUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const token = getAjiraToken();
    const updatesJKT = getBase64String(updatesJKTSubmit);
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        updatesJKT,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const code = response?.data.code;
      if (code == 1) {
        handleCloseJKTUpdate();
        setIsLoading(false);
        setcallBack(code + 1);
        var msg = response?.data?.message;
        const info = { type: "success", message: msg };
        createNotification(info)();
      } else {
        setIsLoading(false);
        var msg = response?.data?.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    } catch (error) {
      setIsLoading(false);
      const msg = error?.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    }
  };

  //----------------------------------------Base64_PDF
  const handleFileChanges = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.type !== "application/pdf") {
        setErrorMessage("Invalid file type. Please upload a PDF file.");
        return;
      }
      if (selectedFile.size > 500000) {
        setErrorMessage(
          "File size is too large. Please upload a file smaller than 500KB."
        );
        return;
      }
      setErrorMessage("");
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        let base64File = reader?.result;
        base64File.toString();
        setAttachmentPath(base64File);
      };
    }
  };

  //----------------------------------------functionBase64String
  function getBase64String(JKT_Upload) {
    const pdfDoc = JKT_Upload.AttachmentPath;
    const base64String = pdfDoc.split(",").pop();
    return { ...JKT_Upload, AttachmentPath: base64String };
  }

  //----------------------------------------JKTLookUp
  // useEffect(() => {
  //   getCamps();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getCamps(); 
      } catch (err) {
        setError('Failed to fetch data. Please check your internet connection.');
        console.error('Error fetching camps:', err);
      }
    };

    fetchData();
  }, []);



  function getCamps() {
    var payload = {
      actionid: "Resources",
      ResourceType: "jkt",
      ResourceParam: "",
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setJKTData(data);
      });
  }

  const JKTInputChange = (e) => {
    JKTData?.map((obj) => {
      var jktID = obj?.ResourceName;
      if (e.target.value == jktID) {
        setCampCode(jktID);
      }
    });
  };

  //----------------------------------------getEducationLevel
  useEffect(() => {
    getEducationLevel();
  }, []);

  

  function getEducationLevel() {
    var payload = {
      actionid: "Resources",
      ResourceType: "educationlevel",
      ResourceParam: "",
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setEducationLevelData(data);
      });
  }

  const EducationLevelInputChange = (e) => {
    EducationLevelData?.map((obj) => {
      var EducationLevelID = obj?.ResourceID;
      if (e.target.value == EducationLevelID) {
        setEducationLevel(EducationLevelID);
        getPerformanceHandler(EducationLevelID);
      }
    });
  };

  //----------------------------------------getPerformance
  function getPerformanceHandler(EducationLevelID) {
    var payload = {
      actionid: "Resources",
      ResourceType: "Performance",
      ResourceParam: EducationLevelID,
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setPerformanceLevelData(data);
      });
  }

  const PerformanceInputChange = (e) => {
    PerformanceLevelData?.map((obj) => {
      var PerformanceID = obj?.ResourceID;
      if (e.target.value == PerformanceID) {
        setPerformanceLevel(PerformanceID);
        getGradeHandler(PerformanceID);
      }
    });
  };

  //----------------------------------------getGrade
  function getGradeHandler(PerformanceID) {
    var payload = {
      actionid: "Resources",
      ResourceType: "grade",
      ResourceParam: PerformanceID,
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setGradeLevelData(data);
      });
  }

  const GradeInputChange = (e) => {
    GradeLevelData?.map((obj) => {
      var GradeID = obj?.ResourceID;
      if (e.target.value == GradeID) {
        setGradeLevel(GradeID);
      }
    });
  };

  //----------------------------------------getSpecialised
  useEffect(() => {
    getSpecialised();
  }, []);

  function getSpecialised() {
    var payload = {
      actionid: "Resources",
      ResourceType: "Professional",
      ResourceParam: "",
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response.data;
        setSpecialisedData(data);
      });
  }

  const SpecialisedInputChange = (e) => {
    SpecialisedData?.map((obj) => {
      var SpecialisedID = obj.ResourceID;
      if (e.target.value == SpecialisedID) {
        setSpecialised(SpecialisedID);
      }
    });
  };

  return (
    <>
      <Header />

      <div>
      {error && <p>{error}</p>}
      {

      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 module-content">
            <br />
            <div class="col-sm-12">
              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 tab-registration-menu hidden-xs">
                <div className="list-group">
                  <Link
                    to="#"
                    className="list-group-item fw-light text-primary done"
                  >
                    Personal Information
                  </Link>
                  <Link
                    to="#"
                    className="list-group-item fw-light text-primary active"
                  >
                    Education Background
                  </Link>
                  <Link to="/professionals" className="list-group-item ">
                    Other profession
                  </Link>
                  <Link
                    onClick={handleShowResetPassword}
                    className="list-group-item "
                  >
                    Change Passowrd
                  </Link>
                </div>
              </div>
              <div
                className="col-lg-10 col-md-10 col-sm-10 col-xs-10 tab-registration-tab"
                style={{ marginTop: "1px", paddingRight: "1px" }}
              >
                <div className="tab-registration-content active">
                  <input type="hidden" name="_csrf" value="" />
                  <div className="box box-default">
                    <div className="box-header with-border">
                      <h3 className="box-title">
                        Education Background, JKT/JKU
                      </h3>
                    </div>
                    <div className="box-body">
                      <div className="row">
                        <div class="box-body" style={{ display: "block" }}>
                          <div class="row">
                            <div class="col-md-12">
                              <button
                                onClick={handleShowEducationBackg}
                                class="btn btn-info pull-left"
                              >
                                <i class="fa fa-plus"></i> Add Education Info.
                              </button>

                              <button
                                onClick={handleShow}
                                className="btn btn-info pull-right"
                              >
                                <i class="fa fa-plus"></i> Add JKT/JKU Info.
                              </button>

                              <br />
                              <table class="table table-striped custab">
                                <thead>
                                  <tr>
                                    <th style={{ width: "15%" }}>
                                      Education Level
                                    </th>
                                    <th style={{ width: "20%" }}>
                                      Registration No.
                                    </th>
                                    <th style={{ width: "30%" }}>
                                      College/School
                                    </th>
                                    <th style={{ width: "15%" }}>
                                      Completed Year
                                    </th>
                                    <th
                                      style={{ width: "20%" }}
                                      className="text-center"
                                    >
                                      Action
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {Data?.map((item) => (
                                    <tr key={item.id}>
                                      <td className="text-uppercase align-middle">
                                        {item?.EducationLevelName}
                                      </td>
                                      <td className="text-uppercase align-middle">
                                        {item?.IndexNo}
                                      </td>
                                      <td className="text-uppercase align-middle">
                                        {item?.InstitutionName}
                                      </td>
                                      <td className="text-uppercase align-middle">
                                        {item?.CompletedYear}
                                      </td>
                                      <td className="text-center">
                                        <button
                                          className="btn text-danger"
                                          onClick={() =>
                                            setUpdateEducationData(item)
                                          }
                                        >
                                          <i className="fa fa-edit"></i> Edit
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>

                              <table class="table table-striped custab">
                                <thead>
                                  <tr>
                                    <th style={{ width: "15%" }}>
                                      Force Number
                                    </th>
                                    <th style={{ width: "50%" }}>Camp Code</th>
                                    <th style={{ width: "15%" }}>
                                      Completed Year
                                    </th>
                                    <th
                                      style={{ width: "20%" }}
                                      className="text-center"
                                    >
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {JKTDataTable?.map((item) => (
                                    <tr key={item?.id}>
                                      <td className="text-uppercase align-middle">
                                        {item?.ForceNumber}
                                      </td>
                                      <td className="text-uppercase align-middle">
                                        {item?.CampCode}
                                      </td>
                                      <td className="text-uppercase align-middle">
                                        {item?.CompleteYear}
                                      </td>
                                      <td className="text-center">
                                        <button
                                          className="btn text-danger"
                                          onClick={() => setUpdateJKTData(item)}
                                        >
                                          <i className="fa fa-edit"></i> Edit
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>

                              {/*----------------------------------------- EducationBackg_Upload */}

                              <Modal
                                size="lg"
                                show={showEducationBackg}
                                onHide={handleCloseEducationBackg}
                              >
                                <Form
                                  id="jkt-form"
                                  onSubmit={handleSubmitEducationBackg}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>
                                      Education Background
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <div className="box-body">
                                      <div className="row">
                                        <div
                                          class="box-body"
                                          style={{ display: "block" }}
                                        >
                                          <div class="row">
                                            <div class="col-md-12">
                                              <br />

                                              <div className="row">
                                                <div className="col-md-6">
                                                  <div className="form-group">
                                                    Education Level
                                                    <select
                                                      id="EducationLevelID"
                                                      className="form-control text-uppercase"
                                                      name="EducationLevel"
                                                      value={EducationLevel}
                                                      onChange={(e) =>
                                                        EducationLevelInputChange(
                                                          e
                                                        )
                                                      }
                                                      required
                                                    >
                                                      <option>Select..</option>
                                                      {EducationLevelData?.map(
                                                        (obj) => {
                                                          return (
                                                            <option
                                                              key={
                                                                obj.ResourceID
                                                              }
                                                              value={
                                                                obj.ResourceID
                                                              }
                                                            >
                                                              {obj.ResourceName}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                    </select>
                                                    <p className="help-block help-block-error"></p>
                                                  </div>
                                                </div>
                                                <div className="col-md-6">
                                                  <div className="form-group field-InstitutionName. required">
                                                    College / School Name
                                                    <input
                                                      type="text"
                                                      className="form-control text-uppercase"
                                                      name="InstitutionName"
                                                      onChange={(e) =>
                                                        setInstitutionName(
                                                          e.target.value
                                                        )
                                                      }
                                                      value={InstitutionName}
                                                      aria-required="true"
                                                    />
                                                    <p className="help-block help-block-error"></p>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col-md-3">
                                                  <div className="form-group field-IndexNo required">
                                                    IndexNo./ RegNo.
                                                    <input
                                                      type="text"
                                                      className="form-control text-uppercase"
                                                      placeholder="S0149/0011/2018"
                                                      name="IndexNo"
                                                      onChange={(e) =>
                                                        setIndexNo(
                                                          e.target.value
                                                        )
                                                      }
                                                      value={IndexNo}
                                                      aria-required="true"
                                                    />
                                                    <p className="help-block help-block-error"></p>
                                                  </div>
                                                </div>
                                                <div className="col-md-3">
                                                  <div className="form-group field-GpaID required">
                                                    Education Performance
                                                    <select
                                                      id="PerformanceID"
                                                      className="form-control text-uppercase"
                                                      name="Performance"
                                                      value={PerformanceLevel}
                                                      onChange={(e) =>
                                                        PerformanceInputChange(
                                                          e
                                                        )
                                                      }
                                                      required
                                                    >
                                                      <option>Select..</option>
                                                      {PerformanceLevelData?.map(
                                                        (obj) => {
                                                          return (
                                                            <option
                                                              key={
                                                                obj.ResourceID
                                                              }
                                                              value={
                                                                obj.ResourceID
                                                              }
                                                            >
                                                              {obj.ResourceName}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                    </select>
                                                    <p className="help-block help-block-error"></p>
                                                  </div>
                                                </div>

                                                <div className="col-md-3">
                                                  <div className="form-group field-pfNo. required">
                                                    Grade
                                                    <select
                                                      id="GradeLevel"
                                                      className="form-control text-uppercase"
                                                      name="GradeID"
                                                      value={GradeLevel}
                                                      onChange={(e) =>
                                                        GradeInputChange(e)
                                                      }
                                                      required
                                                    >
                                                      <option>Select..</option>
                                                      {GradeLevelData?.map(
                                                        (obj) => {
                                                          return (
                                                            <option
                                                              key={
                                                                obj.ResourceID
                                                              }
                                                              value={
                                                                obj.ResourceID
                                                              }
                                                            >
                                                              {obj.ResourceName}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                    </select>
                                                    <p className="help-block help-block-error"></p>
                                                  </div>
                                                </div>
                                                <div className="col-md-3">
                                                  <div className="form-group field-pfNo. required">
                                                    Specialization
                                                    <select
                                                      id="Specialised"
                                                      className="form-control text-uppercase"
                                                      name="SpecialisedID"
                                                      value={Specialised}
                                                      onChange={(e) =>
                                                        SpecialisedInputChange(
                                                          e
                                                        )
                                                      }
                                                    >
                                                      <option>Select..</option>
                                                      {SpecialisedData?.map(
                                                        (obj) => {
                                                          return (
                                                            <option
                                                              key={
                                                                obj.ResourceID
                                                              }
                                                              value={
                                                                obj.ResourceID
                                                              }
                                                            >
                                                              {obj.ResourceName}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                    </select>
                                                    <p className="help-block help-block-error"></p>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col-md-6">
                                                  <div className="form-group field-MembershipNo required">
                                                    Qualified Certificate Name
                                                    <input
                                                      type="text"
                                                      className="form-control text-uppercase"
                                                      name="CourseName"
                                                      placeholder="CSEE"
                                                      onChange={(e) =>
                                                        setCourseName(
                                                          e.target.value
                                                        )
                                                      }
                                                      value={CourseName}
                                                      aria-required="true"
                                                    />
                                                    <p className="help-block help-block-error"></p>
                                                  </div>
                                                </div>

                                                <div className="col-md-3">
                                                  <div className="form-group field-pfNo. required">
                                                    Completed Year
                                                    <select
                                                      className="form-control text-uppercase"
                                                      name="EducationLevel"
                                                      value={CompletedYear}
                                                      onChange={(e) =>
                                                        handleCompletedYear(e)
                                                      }
                                                      required
                                                    >
                                                      <option value="" disabled>
                                                        Select..
                                                      </option>
                                                      {years?.map((year) => (
                                                        <option
                                                          key={year}
                                                          value={year}
                                                        >
                                                          {year}
                                                        </option>
                                                      ))}
                                                    </select>
                                                    <p className="help-block help-block-error"></p>
                                                  </div>
                                                </div>

                                                <div className="col-md-3">
                                                  <div className="form-group field-MembershipNo required">
                                                    Attachment Certificate
                                                    <input
                                                      type="file"
                                                      className="form-control"
                                                      name="AttachmentPath"
                                                      onChange={
                                                        handleFileChanges
                                                      }
                                                      required
                                                    />
                                                    {errorMessage && (
                                                      <p
                                                        style={{
                                                          color: "red",
                                                        }}
                                                      >
                                                        {errorMessage}
                                                      </p>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={handleCloseEducationBackg}
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      variant="primary"
                                      type="submit"
                                      onClick={handleCloseEducationBackg}
                                    >
                                      {isLoading ? "Loading.." : "Submit"}
                                    </Button>
                                  </Modal.Footer>
                                </Form>
                              </Modal>

                              <Modal size="lg" show={show} onHide={handleClose}>
                                <Form id="jkt-form" onSubmit={handleSubmit}>
                                  <Modal.Header closeButton>
                                    <Modal.Title>
                                      JKT/JKU Information
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <div className="box-body">
                                      <div className="row">
                                        <div
                                          class="box-body"
                                          style={{ display: "block" }}
                                        >
                                          <div class="row">
                                            <div class="col-md-12">
                                              <div className="row">
                                                <div className="col-md-6">
                                                  <div className="form-group">
                                                    <label
                                                      htmlFor="BirthCertificateImage"
                                                      className="control-label"
                                                    >
                                                      Training Camp
                                                    </label>
                                                    <select
                                                      className="form-control text-uppercase"
                                                      name="EducationLevel"
                                                      value={CampCode}
                                                      onChange={(e) =>
                                                        JKTInputChange(e)
                                                      }
                                                      required
                                                    >
                                                      <option>Select ..</option>
                                                      {JKTData?.map((obj) => {
                                                        return (
                                                          <option
                                                            key={obj.ResourceID}
                                                            value={
                                                              obj.ResourceName
                                                            }
                                                          >
                                                            {obj.ResourceName}
                                                          </option>
                                                        );
                                                      })}
                                                    </select>
                                                    <p className="help-block help-block-error"></p>
                                                  </div>
                                                </div>

                                                <div className="col-md-6">
                                                  <div className="form-group field-MembershipNo required">
                                                    <label
                                                      htmlFor="BirthCertificateImage"
                                                      className="control-label"
                                                    >
                                                      Force Number
                                                    </label>
                                                    <input
                                                      type="text"
                                                      className="form-control text-uppercase"
                                                      name="ForceNumber"
                                                      placeholder="Force number.."
                                                      onChange={(e) =>
                                                        setForceNumber(
                                                          e.target.value
                                                        )
                                                      }
                                                      value={ForceNumber}
                                                      aria-required="true"
                                                    />
                                                    <p className="help-block help-block-error"></p>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="row">
                                                <div className="col-md-6">
                                                  <div className="form-group">
                                                    <label
                                                      htmlFor="BirthCertificateImage"
                                                      className="control-label"
                                                    >
                                                      Year of Completion
                                                    </label>
                                                    <select
                                                      className="form-control"
                                                      name="EducationLevel"
                                                      value={CompleteYear}
                                                      onChange={(e) =>
                                                        handleCompleteYear(e)
                                                      }
                                                      required
                                                    >
                                                      <option value="" disabled>
                                                        Select Year
                                                      </option>
                                                      {years?.map((year) => (
                                                        <option
                                                          key={year}
                                                          value={year}
                                                        >
                                                          {year}
                                                        </option>
                                                      ))}
                                                    </select>
                                                    <p className="help-block help-block-error"></p>
                                                  </div>
                                                </div>

                                                <div className="col-md-6">
                                                  <div className="form-group field-MembershipNo required">
                                                    <label
                                                      htmlFor="BirthCertificateImage"
                                                      className="control-label"
                                                    >
                                                      Attachment Certificate
                                                    </label>
                                                    <input
                                                      type="file"
                                                      className="form-control"
                                                      name="AttachmentPath"
                                                      onChange={
                                                        handleFileChanges
                                                      }
                                                      required
                                                    />
                                                    {errorMessage && (
                                                      <p
                                                        style={{
                                                          color: "red",
                                                        }}
                                                      >
                                                        {errorMessage}
                                                      </p>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={handleClose}
                                    >
                                      Close
                                    </Button>
                                    <Button
                                      variant="primary"
                                      type="submit"
                                      onClick={handleClose}
                                    >
                                      {isLoading ? "Loading.." : "Submit"}
                                    </Button>
                                  </Modal.Footer>
                                </Form>
                              </Modal>

                              {/*----------------------------------------- Update Backg_pop */}
                              <Modal
                                size="lg"
                                show={updatePop}
                                onHide={handleCloseUpdate}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>
                                    Update Education Background
                                  </Modal.Title>
                                </Modal.Header>
                                <Form
                                  id="updateBackg"
                                  onSubmit={handleSubmitUpdate}
                                >
                                  <Modal.Body>
                                    <div className="box-body">
                                      <div className="row">
                                        <div className="tab-registration-content active">
                                          <input
                                            type="hidden"
                                            name="_csrf"
                                            value=""
                                          />
                                          <div className="box box-default">
                                            <div className="box-body">
                                              <div className="row">
                                                <div
                                                  class="box-body"
                                                  style={{
                                                    display: "block",
                                                  }}
                                                >
                                                  <div class="row">
                                                    <div class="col-md-12">
                                                      <br />

                                                      <div className="row">
                                                        <div className="col-md-6">
                                                          <div className="form-group">
                                                            Education Level
                                                            <select
                                                              id="EducationLevelID"
                                                              className="form-control text-uppercase"
                                                              name="EducationLevel"
                                                              value={
                                                                EducationLevel
                                                              }
                                                              onChange={(e) =>
                                                                EducationLevelInputChange(
                                                                  e
                                                                )
                                                              }
                                                              required
                                                            >
                                                              <option>
                                                                Select..
                                                              </option>
                                                              {EducationLevelData?.map(
                                                                (obj) => {
                                                                  return (
                                                                    <option
                                                                      key={
                                                                        obj.ResourceID
                                                                      }
                                                                      value={
                                                                        obj.ResourceID
                                                                      }
                                                                    >
                                                                      {
                                                                        obj.ResourceName
                                                                      }
                                                                    </option>
                                                                  );
                                                                }
                                                              )}
                                                            </select>
                                                            <p className="help-block help-block-error"></p>
                                                          </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                          <div className="form-group field-InstitutionName. required">
                                                            College / School
                                                            Name
                                                            <input
                                                              type="text"
                                                              className="form-control text-uppercase"
                                                              name="InstitutionName"
                                                              onChange={(e) =>
                                                                setInstitutionName(
                                                                  e.target.value
                                                                )
                                                              }
                                                              value={
                                                                InstitutionName
                                                              }
                                                              aria-required="true"
                                                            />
                                                            <p className="help-block help-block-error"></p>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="row">
                                                        <div className="col-md-3">
                                                          <div className="form-group field-IndexNo required">
                                                            IndexNo./ RegNo.
                                                            <input
                                                              type="text"
                                                              className="form-control text-uppercase"
                                                              placeholder="S0149/0011/2018"
                                                              name="IndexNo"
                                                              onChange={(e) =>
                                                                setIndexNo(
                                                                  e.target.value
                                                                )
                                                              }
                                                              value={IndexNo}
                                                              aria-required="true"
                                                            />
                                                            <p className="help-block help-block-error"></p>
                                                          </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                          <div className="form-group field-GpaID required">
                                                            Education
                                                            Performance
                                                            <select
                                                              id="PerformanceID"
                                                              className="form-control text-uppercase"
                                                              name="Performance"
                                                              value={
                                                                PerformanceLevel
                                                              }
                                                              onChange={(e) =>
                                                                PerformanceInputChange(
                                                                  e
                                                                )
                                                              }
                                                              required
                                                            >
                                                              <option>
                                                                Select..
                                                              </option>
                                                              {PerformanceLevelData?.map(
                                                                (obj) => {
                                                                  return (
                                                                    <option
                                                                      key={
                                                                        obj.ResourceID
                                                                      }
                                                                      value={
                                                                        obj.ResourceID
                                                                      }
                                                                    >
                                                                      {
                                                                        obj.ResourceName
                                                                      }
                                                                    </option>
                                                                  );
                                                                }
                                                              )}
                                                            </select>
                                                            <p className="help-block help-block-error"></p>
                                                          </div>
                                                        </div>

                                                        <div className="col-md-3">
                                                          <div className="form-group field-pfNo. required">
                                                            Grade
                                                            <select
                                                              id="GradeLevel"
                                                              className="form-control text-uppercase"
                                                              name="GradeID"
                                                              value={GradeLevel}
                                                              onChange={(e) =>
                                                                GradeInputChange(
                                                                  e
                                                                )
                                                              }
                                                              required
                                                            >
                                                              <option>
                                                                Select..
                                                              </option>
                                                              {GradeLevelData?.map(
                                                                (obj) => {
                                                                  return (
                                                                    <option
                                                                      key={
                                                                        obj.ResourceID
                                                                      }
                                                                      value={
                                                                        obj.ResourceID
                                                                      }
                                                                    >
                                                                      {
                                                                        obj.ResourceName
                                                                      }
                                                                    </option>
                                                                  );
                                                                }
                                                              )}
                                                            </select>
                                                            <p className="help-block help-block-error"></p>
                                                          </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                          <div className="form-group field-pfNo. required">
                                                            Specialised
                                                            <select
                                                              id="Specialised"
                                                              className="form-control text-uppercase"
                                                              name="SpecialisedID"
                                                              value={
                                                                Specialised
                                                              }
                                                              onChange={(e) =>
                                                                SpecialisedInputChange(
                                                                  e
                                                                )
                                                              }
                                                            >
                                                              <option>
                                                                Select..
                                                              </option>
                                                              {SpecialisedData?.map(
                                                                (obj) => {
                                                                  return (
                                                                    <option
                                                                      key={
                                                                        obj.ResourceID
                                                                      }
                                                                      value={
                                                                        obj.ResourceID
                                                                      }
                                                                    >
                                                                      {
                                                                        obj.ResourceName
                                                                      }
                                                                    </option>
                                                                  );
                                                                }
                                                              )}
                                                            </select>
                                                            <p className="help-block help-block-error"></p>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      <div className="row">
                                                        <div className="col-md-6">
                                                          <div className="form-group field-MembershipNo required">
                                                            Qualified
                                                            Certificate Name
                                                            <input
                                                              type="text"
                                                              className="form-control text-uppercase"
                                                              name="CourseName"
                                                              placeholder="CSEE"
                                                              onChange={(e) =>
                                                                setCourseName(
                                                                  e.target.value
                                                                )
                                                              }
                                                              value={CourseName}
                                                              aria-required="true"
                                                            />
                                                            <p className="help-block help-block-error"></p>
                                                          </div>
                                                        </div>

                                                        <div className="col-md-3">
                                                          <div className="form-group field-pfNo. required">
                                                            Completed Year
                                                            <select
                                                              className="form-control text-uppercase"
                                                              name="EducationLevel"
                                                              value={
                                                                CompletedYear
                                                              }
                                                              onChange={(e) =>
                                                                handleCompletedYear(
                                                                  e
                                                                )
                                                              }
                                                              required
                                                            >
                                                              <option
                                                                value=""
                                                                disabled
                                                              >
                                                                Select..
                                                              </option>
                                                              {years?.map(
                                                                (year) => (
                                                                  <option
                                                                    key={year}
                                                                    value={year}
                                                                  >
                                                                    {year}
                                                                  </option>
                                                                )
                                                              )}
                                                            </select>
                                                            <p className="help-block help-block-error"></p>
                                                          </div>
                                                        </div>

                                                        <div className="col-md-3">
                                                          <div className="form-group field-MembershipNo required">
                                                            Attachment
                                                            Certificate
                                                            <input
                                                              type="file"
                                                              className="form-control"
                                                              name="AttachmentPath"
                                                              onChange={
                                                                handleFileChanges
                                                              }
                                                              aria-required="true"
                                                            />
                                                            {errorMessage && (
                                                              <p
                                                                style={{
                                                                  color: "red",
                                                                }}
                                                              >
                                                                {errorMessage}
                                                              </p>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <Modal.Footer>
                                            <Button
                                              variant="secondary"
                                              onClick={handleCloseUpdate}
                                            >
                                              Close
                                            </Button>
                                            <Button
                                              variant="primary"
                                              type="submit"
                                              onClick={handleCloseUpdate}
                                            >
                                              {isLoading
                                                ? "Loading.."
                                                : "Submit"}
                                            </Button>
                                          </Modal.Footer>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal.Body>
                                </Form>
                              </Modal>

                              {/*----------------------------------------- Update JKT_pop */}
                              <Modal
                                size="lg"
                                show={updateJKT}
                                onHide={handleCloseJKTUpdate}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>
                                    Update JKT/JKU Infomation
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <div className="box-body">
                                    <div className="row">
                                      <form
                                        id="step-one-form"
                                        onSubmit={handleSubmitJKTUpdate}
                                      >
                                        <div className="box-body">
                                          <div className="row">
                                            <div
                                              class="box-body"
                                              style={{ display: "block" }}
                                            >
                                              <div class="row">
                                                <div class="col-md-12">
                                                  <div className="row">
                                                    <div className="col-md-6">
                                                      <div className="form-group">
                                                        <label
                                                          htmlFor="BirthCertificateImage"
                                                          className="control-label"
                                                        >
                                                          Training Camp
                                                        </label>
                                                        <select
                                                          className="form-control text-uppercase"
                                                          name="EducationLevel"
                                                          value={CampCode}
                                                          onChange={(e) =>
                                                            JKTInputChange(e)
                                                          }
                                                          required
                                                        >
                                                          <option>
                                                            Select ..
                                                          </option>

                                                          {JKTData?.map(
                                                            (obj) => {
                                                              return (
                                                                <option
                                                                  key={
                                                                    obj.ResourceID
                                                                  }
                                                                  value={
                                                                    obj.ResourceName
                                                                  }
                                                                >
                                                                  {
                                                                    obj.ResourceName
                                                                  }
                                                                </option>
                                                              );
                                                            }
                                                          )}
                                                        </select>
                                                        <p className="help-block help-block-error"></p>
                                                      </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                      <div className="form-group field-MembershipNo required">
                                                        <label
                                                          htmlFor="BirthCertificateImage"
                                                          className="control-label"
                                                        >
                                                          Force Number
                                                        </label>
                                                        <input
                                                          type="text"
                                                          className="form-control text-uppercase"
                                                          name="ForceNumber"
                                                          placeholder="Force number.."
                                                          onChange={(e) =>
                                                            setForceNumber(
                                                              e.target.value
                                                            )
                                                          }
                                                          value={ForceNumber}
                                                          aria-required="true"
                                                        />
                                                        <p className="help-block help-block-error"></p>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="row">
                                                    <div className="col-md-6">
                                                      <div className="form-group">
                                                        <label
                                                          htmlFor="BirthCertificateImage"
                                                          className="control-label"
                                                        >
                                                          Year of Completion
                                                        </label>
                                                        <select
                                                          className="form-control"
                                                          name="EducationLevel"
                                                          value={CompleteYear}
                                                          onChange={(e) =>
                                                            handleCompleteYear(
                                                              e
                                                            )
                                                          }
                                                          required
                                                        >
                                                          <option
                                                            value=""
                                                            disabled
                                                          >
                                                            Select Year
                                                          </option>
                                                          {years?.map(
                                                            (year) => (
                                                              <option
                                                                key={year}
                                                                value={year}
                                                              >
                                                                {year}
                                                              </option>
                                                            )
                                                          )}
                                                        </select>
                                                        <p className="help-block help-block-error"></p>
                                                      </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                      <div className="form-group field-MembershipNo required">
                                                        <label
                                                          htmlFor="BirthCertificateImage"
                                                          className="control-label"
                                                        >
                                                          Attachment Certificate
                                                        </label>
                                                        <input
                                                          type="file"
                                                          className="form-control"
                                                          name="AttachmentPath"
                                                          onChange={
                                                            handleFileChanges
                                                          }
                                                          aria-required="true"
                                                        />

                                                        {errorMessage && (
                                                          <p
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            {errorMessage}
                                                          </p>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <Modal.Footer>
                                          <Button
                                            variant="primary"
                                            type="submit"
                                          >
                                            {isLoading ? "Loading.." : "Submit"}
                                          </Button>
                                        </Modal.Footer>
                                      </form>
                                    </div>
                                  </div>
                                </Modal.Body>
                              </Modal>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="box-footer">
                    <div className="row">
                      <div className="col-md-4">
                        <Link
                          to="/home"
                          className="btn btn-labeled btn-block btn-default"
                        >
                          <i className="fa fa-home"></i> | Dashboard
                        </Link>
                      </div>
                      <div className="col-md-4"></div>
                      <div className="col-md-4">
                        <button
                          className="btn btn-default btn-block btn-label-next"
                          onClick={handleSubmitProfile}
                        >
                          Submit | <i className="fa fa-save"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </div>

      {/*-----------------------------------------------ResetPassword-*/}
      <Modal show={showResetPassword} onHide={handleCloseResetPassword}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitPassword}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="20010547641550000123"
                // autoFocus
                value={UsernameReset}
                onChange={(e) => setUsernameReset(e.target.value)}
                maxLength={20}
                minLength={20}
                title="NIN Format: 20010547641550000123"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Old Password"
                // autoFocus
                value={OldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="New Password"
                // autoFocus
                value={NewPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {errors.password && (
                <span className="text-danger">{errors.password}</span>
              )}

              {NewPassword && !checkResetPasswordPattern() && (
                <p style={{ color: "red" }}>
                  Password must be at least 8 characters long and include at
                  least one uppercase letter, one lowercase letter, one number,
                  and one special character.
                </p>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Confirm Passowrd</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                // autoFocus
                value={ConfirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>

            {passwordMatchError && (
              <div style={{ color: "red" }} className="error-message">
                {passwordMatchError}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseResetPassword}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
export default EducationBackg_Table;
