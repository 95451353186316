import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getUserInfo, url, getAjiraToken } from "../../utility/Common";
import { createNotification } from "../Popup";
import CallForInterviewPDF from "../PDF/CallForInterviewPDF";
import JoinInstructionPDF from "../PDF/JoinInstructionPDF"; // Added import for JoinInstructionPDF

function NavSection() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userNotification, setUserNotification] = useState("");
  const [AppStageID, setAppStageID] = useState("");
  const [LevelName, setLevelName] = useState("");
  const [IsKoz, setIsKoz] = useState(null); // Changed initial value to null for better type handling
  const [JoinInstructionPDF, setJoinInstructionPDF] = useState("");

  const handleButtonApply = () => {
    if (!LevelName) {
      navigate("/educationbackg");
    } else {
      if (!AppStageID) {
        navigate("/newapplication");
      } else if (AppStageID === 210) {
        navigate("/applicationattachment");
      } else if (AppStageID === 220) {
        navigate("/declaration");
      }
    }
  };

  useEffect(() => {
    const fetchStatus = () => {
      setLoading(true);
      const payload = {
        SystemId: "UHM005",
        AccessorId: getUserInfo()?.ApplicantID,
        ManagementDataType: "Status",
        ResourceType: "CheckStatus",
        Username: getUserInfo()?.NIN,
      };
      const token = getAjiraToken();

      fetch(url() + "erms_applicationmanagement", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((response) => {
          const data = response?.data?.[0];
          if (data) {
            setUserNotification(data?.UserNotification || "");
            setAppStageID(data?.ApplicationStageID || "");
            setLevelName(data?.LevelName || "");
            setIsKoz(data?.IsKoz ?? null); // Safely handle null or undefined values
            setJoinInstructionPDF(data?.JoinInstructionPDF || "");
          }
        })
        .catch((error) => {
          const msg =
            error?.message || "An error occurred. Please try again later.";
          createNotification({ type: "error", message: msg })();
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchStatus();
  }, []);

  const handleButtonProfile = () => {
    navigate("/educationbackg");
  };

  return (
    <>
      <div className="row">
        <br />
        <div className="col-sm-12">
          <section id="what-we-do">
            <div className="row mt-5">
              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <div className="card">
                  {IsKoz === 0 ? (
                    <div className="card-block block-7">
                      <h3 className="card-title page-header m-reset-0">
                        Your Profile
                      </h3>
                      <div
                        className="card-title m-reset-0"
                        onClick={handleButtonProfile}
                      >
                        Update Profile{" "}
                        <i className="ml-2 fa fa-angle-double-right"></i>
                      </div>
                    </div>
                  ) : (
                    <>
                      {IsKoz !== 0 && (
                        <div className="text-secondary card-title m-reset-0">
                          Course deadline closed{" "}
                          <i className="ml-2 fa fa-close"></i>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <div className="card">
                  {IsKoz === 0 ? (
                    <div className="card-block block-7">
                      <h3 className="card-title page-header m-reset-0">
                        Job Application
                      </h3>
                      <div
                        className="card-title m-reset-0"
                        onClick={handleButtonApply}
                      >
                        Start Application{" "}
                        <i className="ml-2 fa fa-angle-double-right"></i>
                      </div>
                    </div>
                  ) : (
                    <>
                      {IsKoz !== 0 && (
                        <div className="text-secondary card-title m-reset-0">
                          Course deadline closed{" "}
                          <i className="ml-2 fa fa-close"></i>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                <div className="card">
                  <div className="card-block block-3">
                    <h3 className="card-title page-header m-reset-0">
                      Check Status
                    </h3>
                    <div className="card-title m-reset-0 text-danger">
                      {loading ? (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <>
                          {userNotification}
                          {IsKoz === 2 && (
                            <div className="mt-2">
                              <Link to="#" onClick={() => setIsKoz(true)}></Link>
                              {IsKoz && <CallForInterviewPDF />}
                            </div>
                          )}
                          {IsKoz === 3 && (
                            <div className="mt-2">
                              <Link to="#" onClick={() => setIsKoz(true)}></Link>
                              {IsKoz && <JoinInstructionPDF />}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      </div>
    </>
  );
}

export default NavSection;
