import React from "react";
import Header from "../Header";
import { Link } from "react-router-dom";
import "../../includes/myStyles.css";

function Status() {
  return (
    <>
      <Header />
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 module-content">
            <br />
            <div class="col-sm-12">
              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 tab-registration-menu hidden-xs">
                <div className="list-group">
                  <Link
                    to="#"
                    className="list-group-item fw-light text-primary done"
                  >
                    Personal Information
                  </Link>
                  <Link
                    to="#"
                    className="list-group-item fw-light text-primary done"
                  >
                    Education Background
                  </Link>
                  <Link
                    to="#"
                    className="list-group-item fw-light text-primary done"
                  >
                    Other Proffesionals
                  </Link>
                  <Link className="list-group-item fw-light text-primary active">
                    Submition{" "}
                  </Link>
                </div>
              </div>
              <div
                className="col-lg-10 col-md-10 col-sm-10 col-xs-10 tab-registration-tab"
                style={{ marginTop: "1px", paddingRight: "1px" }}
              >
                <div className="tab-registration-content active">
                  <input type="hidden" name="_csrf" value="" />
                  <div className="box box-default">
                    <div className="box panel panel-default">
                      <div className="box-header with-border panel-heading">
                        <h3 className="box-title">Application Status</h3>
                        <Link
                          to="#"
                          className="btn pull-right btn-success btn-xs"
                        >
                          <i className="fa fa-download"></i> Download your C.V
                        </Link>{" "}
                      </div>
                      <div className="box-body panel-body">
                        <div className="row">
                          <div className="col-sm-12">
                            <br />
                            <p
                              style={{ fontSize: "23px", textAlign: "center" }}
                            >
                              Submited successful
                            </p>
                          </div>
                        </div>
                        <div className="row" style={{ textAlign: "center" }}>
                          <br />
                          <Link to="/home">
                            <button className="btn btn-default btn-label-next">
                              <i className="fa fa-home"> | </i>Go back to Home
                              Page
                            </button>
                          </Link>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Status;
