import { React, useEffect, useState } from "react";
import Header from "../Header";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import {
  url,
  urlResource,
  getUserInfo,
  setUserInfo,
  getAjiraToken,
} from "../../utility/Common";
import axios from "axios";
import { createNotification } from "../Popup";

function Professional_Upload() {
  const navigate = useNavigate();

  const [SpecialisedData, setSpecialisedData] = useState([]);
  const [IndexNo, setIndexNo] = useState("");
  const [ProfessionalID, setProfessionalID] = useState("");
  const [CourseName, setCourseName] = useState("");
  const [CompletedYear, setCompletedYear] = useState("");
  const [InstitutionName, setInstitutionName] = useState("");
  const [AttachmentPath, setAttachmentPath] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  var md5 = require("md5");
  const [showResetPassword, setShowResetPassword] = useState(false);
  const handleCloseResetPassword = () => setShowResetPassword(false);
  const handleShowResetPassword = () => setShowResetPassword(true);
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [OldPassword, setOldPassword] = useState("");
  const [UsernameReset, setUsernameReset] = useState("");
  const [errors, setErrors] = useState({});

  const Specialised_Upload = {
    SystemId: "UHM005",
    ManagementDataType: "ProfessionalBackground",
    ResourceType: "INSERT",
    ApplicantID: getUserInfo()?.ApplicantID,
    IndexNo,
    ProfessionalID,
    CourseName,
    CompletedYear,
    InstitutionName,
    AttachmentPath,
    AccessorId: getUserInfo()?.ApplicantID,
  };

  //----------------------------------------functionBase64String
  function getBase64String(Specialised_Upload) {
    const pdfDoc = Specialised_Upload.AttachmentPath;
    const base64String = pdfDoc.split(",").pop();
    return { ...Specialised_Upload, AttachmentPath: base64String };
  }

  const handleCompletedYear = (e) => {
    setCompletedYear(e.target.value);
  };

  const startYear = 2000;
  const endYear = new Date().getFullYear();
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index
  );

  //----------------------------------------submitFunction
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = getAjiraToken();
    const updatedSpecialised_Upload = getBase64String(Specialised_Upload);

    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        updatedSpecialised_Upload,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = response?.data;
      const code = data?.code;

      if (code == 1) {
        setUserInfo(response?.data?.data[0]);
        var msg = response?.data?.message;
        const info = { type: "success", message: msg };
        createNotification(info)();
        navigate("/professionals");
      } else {
        var msg = response?.data?.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    } catch (error) {
      const msg = error?.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    }
  };

  //----------------------------------------getSpecialised
  useEffect(() => {
    getSpecialised();
  }, []);

  function getSpecialised() {
    var payload = {
      actionid: "Resources",
      ResourceType: "Professional",
      ResourceParam: "",
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response.data;
        setSpecialisedData(data);
      });
  }

  const SpecialisedInputChange = (e) => {
    var data = e.target.value;
    if (data) {
      setProfessionalID(data);
    }
  };

  //----------------------------------------Base64_PDF
  const handleFileChanges = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.type !== "application/pdf") {
        setErrorMessage("Invalid file type. Please upload a PDF file.");
        return;
      }
      if (selectedFile.size > 500000) {
        setErrorMessage(
          "File size is too large. Please upload a file smaller than 500KB."
        );
        return;
      }
      setErrorMessage("");
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        let base64File = reader.result;
        base64File.toString();
        setAttachmentPath(base64File);
      };
    }
  };

  const checkResetPasswordPattern = () => {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordPattern?.test(NewPassword);
  };
  
  //-------------------------------------------ResetPassword
  const ResetPassword = {
    SystemId: "UHM005",
    ResourceType: "ResetApplicant",
    ManagementDataType: "ProfileManagement",
    UsernameReset,
    OldPassword: md5(OldPassword),
    NewPassword: md5(NewPassword),
    AccessorId: getUserInfo()?.ApplicantID,
  };

  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    const token = getAjiraToken();

    if (NewPassword !== ConfirmPassword) {
      return setPasswordMatchError("Passwords do not match");
    }
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        ResetPassword,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = response?.data;
      const code = data?.code;
      if (code == 1) {
        navigate("/");
      }
    } catch (error) {
      const msg = error?.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    }
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <>
      <Header />
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 module-content">
            <br />
            <div class="col-sm-12">
              <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 tab-registration-menu hidden-xs">
                <div className="list-group">
                  <Link
                    to="#"
                    className="list-group-item fw-light text-primary done"
                  >
                    Personal Information
                  </Link>
                  <Link
                    to="/educationbackg"
                    className="list-group-item fw-light text-primary done"
                  >
                    Education Background
                  </Link>
                  <Link
                    to="#"
                    className="list-group-item fw-light text-primary active"
                  >
                    Other Profession
                  </Link>
                  <Link
                    onClick={handleShowResetPassword}
                    className="list-group-item "
                  >
                    Change Passowrd
                  </Link>
                </div>
              </div>
              <div
                className="col-lg-10 col-md-10 col-sm-10 col-xs-10 tab-registration-tab"
                style={{ marginTop: "1px", paddingRight: "1px" }}
              >
                <div className="tab-registration-content active">
                  <form id="step-one-form" onSubmit={handleSubmit}>
                    <div className="box box-default">
                      <div className="box-header with-border">
                        <h3 className="box-title">Other Profession</h3>
                      </div>
                      <div className="box-body">
                        <div className="row">
                          <div class="box-body" style={{ display: "block" }}>
                            <div class="row">
                              <div class="col-md-12">
                                <br />
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group field-MembershipNo required">
                                      Qualified Certificate Name
                                      <input
                                        type="text"
                                        className="form-control text-uppercase"
                                        name="CourseName"
                                        placeholder="Cisco Certified Network Professional (CCNP)"
                                        onChange={(e) =>
                                          setCourseName(e.target.value)
                                        }
                                        value={CourseName}
                                        aria-required="true"
                                      />
                                      <p className="help-block help-block-error"></p>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group field-pfNo. required">
                                      Institution Name
                                      <input
                                        type="text"
                                        className="form-control text-uppercase"
                                        name="InstitutionName"
                                        onChange={(e) =>
                                          setInstitutionName(e.target.value)
                                        }
                                        value={InstitutionName}
                                        aria-required="true"
                                      />
                                      <p className="help-block help-block-error"></p>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="form-group field-MembershipNo required">
                                      Registration No.
                                      <input
                                        type="text"
                                        className="form-control text-uppercase"
                                        placeholder="CSCO123456789"
                                        name="IndexNo"
                                        onChange={(e) =>
                                          setIndexNo(e.target.value)
                                        }
                                        value={IndexNo}
                                        aria-required="true"
                                      />
                                      <p className="help-block help-block-error"></p>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group field-pfNo. required">
                                      Specialization
                                      <select
                                        id="SpecialisedID"
                                        className="form-control text-uppercase"
                                        name="SpecialisedID"
                                        value={ProfessionalID}
                                        onChange={(e) =>
                                          SpecialisedInputChange(e)
                                        }
                                      >
                                        <option>Select..</option>
                                        {SpecialisedData?.map((obj) => {
                                          return (
                                            <option
                                              key={obj.ResourceID}
                                              value={obj.ResourceID}
                                            >
                                              {obj.ResourceName}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      <p className="help-block help-block-error"></p>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group field-pfNo. required">
                                      Completed Year
                                      <select
                                        className="form-control text-uppercase"
                                        name="EducationLevel"
                                        value={CompletedYear}
                                        onChange={(e) => handleCompletedYear(e)}
                                        required
                                      >
                                        <option value="" disabled>
                                          Select..
                                        </option>
                                        {years?.map((year) => (
                                          <option key={year} value={year}>
                                            {year}
                                          </option>
                                        ))}
                                      </select>
                                      <p className="help-block help-block-error"></p>
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <div className="form-group field-MembershipNo required">
                                      Attachment Certificate
                                      <input
                                        type="file"
                                        className="form-control"
                                        name="AttachmentPath"
                                        onChange={handleFileChanges}
                                        required
                                      />
                                      {errorMessage && (
                                        <p
                                          style={{
                                            color: "red",
                                          }}
                                        >
                                          {errorMessage}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="box-footer">
                      <div className="row">
                        <div className="col-md-4">
                          <Link
                            to="#"
                            className="btn btn-labeled btn-block btn-default"
                            name="SaveExit"
                            onClick={refreshPage}
                          >
                            Clear |{" "}
                            <i class="fa fa-refresh" aria-hidden="true"></i>
                          </Link>
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                          <button
                            className="btn btn-default btn-block btn-label-next"
                            type="submit"
                          >
                            Next | <i className="fa fa-angle-double-right"></i>{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*-----------------------------------------------ResetPassword-*/}
      <Modal show={showResetPassword} onHide={handleCloseResetPassword}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitPassword}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="20010547641550000123"
                // autoFocus
                value={UsernameReset}
                onChange={(e) => setUsernameReset(e.target.value)}
                maxLength={20}
                minLength={20}
                title="NIN Format: 20010547641550000123"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Old Password"
                // autoFocus
                value={OldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="New Password"
                // autoFocus
                value={NewPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {errors.password && (
                <span className="text-danger">{errors.password}</span>
              )}

              {NewPassword && !checkResetPasswordPattern() && (
                <p style={{ color: "red" }}>
                  Password must be at least 8 characters long and include at
                  least one uppercase letter, one lowercase letter, one number,
                  and one special character.
                </p>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Confirm Passowrd</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                // autoFocus
                value={ConfirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>

            {passwordMatchError && (
              <div style={{ color: "red" }} className="error-message">
                {passwordMatchError}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseResetPassword}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
export default Professional_Upload;
