import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import imm_logo from "../includes/images/imm_logo3.png";
import axios from "axios";
import {
  url,
  setUserInfo,
  getUserInfo,
  setAjiraToken,
} from "../utility/Common";
import { Modal, Button, Form } from "react-bootstrap";
import Question_Modal from "./questionsmodal/Question_Modal";
import { createNotification } from "./Popup";
import pdf from "../includes/documents/User_Manual_For_Aplicant.pdf";
// import isLoading from "../includes/images/";

function Login() {
  const navigate = useNavigate();
  var md5 = require("md5");
  const [errors, setErrors] = useState({});
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [UsernameReset, setUsernameReset] = useState("");
  const [OldPassword, setOldPassword] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [showResetPassword, setShowResetPassword] = useState(false);
  const handleCloseResetPassword = () => setShowResetPassword(false);
  const handleShowResetPassword = () => setShowResetPassword(true);

  const checkPasswordPattern = () => {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordPattern?.test(Password);
  };

  const checkResetPasswordPattern = () => {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordPattern?.test(NewPassword);
  };

  //-------------------------------------------login
  const Login = {
    SystemId: "UHM005",
    ResourceType: "LOGIN",
    ManagementDataType: "ApplicantLogin",
    Username,
    Password: md5(Password),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        Login
      );
      const { code } = response?.data;
      if (code === "1") {
        var userInfo = response?.data?.data[0];
        const token = response?.data?.token;
        setOldPassword(Password);
        setUserInfo(userInfo);
        setAjiraToken(token);
        navigate("/home");
      } else {
        var msg = response?.data?.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    } catch (error) {
      const msg = error?.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    }
  };

  //-------------------------------------------ResetPassword
  const ResetPassword = {
    SystemId: "UHM005",
    ResourceType: "RESET",
    ManagementDataType: "ProfileManagement",
    UsernameReset,
    OldPassword: md5(Password),
    NewPassword: md5(Password),
    AccessorId: getUserInfo()?.ApplicantID,
  };
  const handleSubmitPassword = async (e) => {
    e.preventDefault();

    if (NewPassword !== ConfirmPassword) {
      return setPasswordMatchError("Passwords do not match");
    }
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        ResetPassword
      );
      const data = response?.data;
      const code = data?.code;
      if (code === 1) {
        navigate("/");
      }
    } catch (error) {
      const msg = error.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    }
  };

  //-------------------------------------------css
  const styles = {
    linkContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
  };

  const openPDF = () => {
    window.open(pdf, "_blank");
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center bg-dark vh-100">
        <div className="bg-white p-3 w-50 text-dark">
          <div className="col-md-6">
            <div className="mb-6">
              <img
                src={imm_logo}
                alt="Logo"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  display: "block",
                }}
              />
            </div>
            <br />
            <br />
            <Link
              to="#"
              className="h5 text-primary"
              style={{
                textAlign: "right",
                textDecoration: "none",
              }}
            >
              <em>
                <a href onClick={openPDF}>
                  User Manual &nbsp;
                  <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                </a>
              </em>
            </Link>
          </div>
          <div className="col-md-6">
            <h4
              class="mb-0 mt-3 h1 fw-light text-secondary rounded"
              style={{
                fontSize: "2em",
                fontFamily: "Yu Gothic",
                textAlign: "justify",
              }}
            >
              e-IMMIGRATION RECRUITMENT PORTAL
            </h4>
            <br />
            <form action="" onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="NIN">
                  <strong>Username</strong>
                </label>
                <input
                  type="text"
                  placeholder="20010547641550000123"
                  name="NIN"
                  onChange={(e) => setUsername(e.target.value)}
                  value={Username}
                  className="form-control rounded-0"
                  maxLength={20}
                  minLength={20}
                  title="NIN Format: 20010547641550000123"
                />
                {errors.NIN && (
                  <span className="text-danger">{errors.NIN}</span>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="password">
                  <strong>Password</strong>
                </label>
                <input
                  type="password"
                  placeholder="enter password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={Password}
                  className="form-control rounded-0"
                />
                {errors.password && (
                  <span className="text-danger">{errors.password}</span>
                )}

                {Password && !checkPasswordPattern() && (
                  <p style={{ color: "red" }}>
                    Password must be at least 8 characters long and include at
                    least one uppercase letter, one lowercase letter, one
                    number, and one special character.
                  </p>
                )}
              </div>
              <button type="submit" className="btn btn-success w-100 rounded-0">
                Login
              </button>
              <br />
              <br />
              <Question_Modal />
              <br />
              <br />
              <div style={styles.linkContainer}>
                <div style={styles.leftLink}>
                  <Link
                    style={{
                      textAlign: "right",
                      textDecoration: "none",
                    }}
                    variant="primary"
                    onClick={() => setShow(true)}
                    className="h5 text-primary"
                  >
                    <em>Guidelines!</em>
                    <br />
                  </Link>
                </div>
                <div style={styles.rightLink}>
                  <Link
                    to="#"
                    className="h5 text-primary"
                    onClick={handleShowResetPassword}
                    style={{
                      textAlign: "right",
                      textDecoration: "none",
                    }}
                  >
                    <em>Forgot Password!</em>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/*-----------------------------------------------ForgetPassword-*/}
      <Modal show={showResetPassword} onHide={handleCloseResetPassword}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitPassword}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="20010547641550000123"
                value={UsernameReset}
                onChange={(e) => setUsernameReset(e.target.value)}
                maxLength={20}
                minLength={20}
                title="NIN Format: 20010547641550000123"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Old Password"
                value={OldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="New Password"
                value={NewPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {errors.password && (
                <span className="text-danger">{errors.password}</span>
              )}

              {NewPassword && !checkResetPasswordPattern() && (
                <p style={{ color: "red" }}>
                  Password must be at least 8 characters long and include at
                  least one uppercase letter, one lowercase letter, one number,
                  and one special character.
                </p>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Confirm Passowrd</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                value={ConfirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>

            {passwordMatchError && (
              <div style={{ color: "red" }} className="error-message">
                {passwordMatchError}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseResetPassword}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/*-----------------------------------------------Keynote-*/}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <span className="text-primary">Keynote before you start:</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="col-sm-12">
            <ol>
              <li>
                Barua ya Utambulisho toka Serikali ya Mtaa/Kijiji au Shehia.
              </li>
              <li>
                Kwa waombaji waliopo Makambini, barua zao zipitie kwa Wakuu wa
                Kambi husika.
              </li>
              <li>Nakala ya Cheti cha Kuzaliwa.</li>
              <li>
                Namba au Kitambulisho cha Taifa (NIDA) au Kitambulisho cha
                Mzanzibari.
              </li>
              <li>Vyeti vya kuhitimu shule (Leaving Certificates).</li>
              <li>Vyeti vya Taaluma (Academic Certificates).</li>
              <li>Vyeti vingine vya Fani mbalimbali.</li>
              <li>Wasifu wa Mwombaji (CV).</li>
              <li>Picha Moja ya Pasipoti zenye rangi ya Bluu.</li>
              <li>
                Muombaji anatakiwa ku-scan nyaraka zote pamoja na barua ya
                maombi ya kazi, kila nyaraka isizidi ukubwa wa 500Kb.
              </li>
            </ol>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Login;