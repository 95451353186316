import { React, useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Header from "../Header";
import { Link, useNavigate } from "react-router-dom";
import NavigationSection from "./NavigationSection";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  url,
  getUserInfo,
  setUserInfo,
  getAjiraToken,
  setAppStageID,
} from "../../utility/Common";
import axios from "axios";
import { createNotification } from "../Popup";

function ApplicantAttachments() {
  const navigate = useNavigate();
  const [ApplicantID, setApplicantID] = useState("");
  const [ApplicationID, setApplicationID] = useState("");
  const [Data, setData] = useState([]);
  const [isViewCertificateClicked, setisViewCertificateClicked] =
    useState(false);
  const [showModal, setShowModal] = useState(false);
  const [attInfo, setAttInfo] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [AttachmentPath, setAttachmentPath] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [IsUploaded, setIsUploaded] = useState(false);
  const [attachmentCode, setAttachmentCode] = useState(null);
  const [attachmentName, setAttachmentName] = useState(null);
  const [base64View, setBase64View] = useState(null);
  const [callBack, setcallBack] = useState(null);

  const [showCert, setShowCert] = useState(false);
  const handleCloseCert = () => setShowCert(false);
  const handleShowCert = () => setShowCert(true);

  const openModal = (data) => {
    const attCode = data.AttachmentCode;
    const attName = data.AttachmentName;
    setAttachmentCode(attCode);
    setAttachmentName(attName);
    setAttInfo(data);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  //----------------------------------------TableAttachments AttachmentCode
  const UploadAttachments = {
    SystemId: "UHM005",
    ManagementDataType: "InsertAttachment",
    ResourceType: "INSERT",
    ApplicationID: getUserInfo()?.ApplicationID,
    AppAttachmentName: "",
    AttachmentPath: AttachmentPath,
    AppAttachCode: attachmentCode,
    ApplicantID: getUserInfo()?.ApplicantID,
    AccessorId: getUserInfo()?.ApplicantID,
  };

  //----------------------------------------functionBase64String
  function getBase64String(UploadAttachments) {
    const pdfDoc = UploadAttachments.AttachmentPath;
    const base64String = pdfDoc.split(",").pop();
    return { ...UploadAttachments, AttachmentPath: base64String };
  }

  //----------------------------------------submitFunctionModal
  const handleSubmitDocument = async (e) => {
    e.preventDefault();
    const token = getAjiraToken();

    setIsLoading(true);
    const updatedUploadAttachments = getBase64String(UploadAttachments);

    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        updatedUploadAttachments,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setIsLoading(false);
      const code = response?.data.code;
      if (code == 1) {
        closeModal();
        setcallBack(callBack + 1);
        var msg = response?.data.message;
        const info = { type: "success", message: msg };
        createNotification(info)();
      } else {
        setIsLoading(false);
        var msg = response?.data.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    } catch (error) {
      setIsLoading(false);
      const msg = error.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
      console.error(error);
    }
  };

  //----------------------------------------nextButtonToDeclaration
  const moveToDeclaration = {
    SystemId: "UHM005",
    ManagementDataType: "Status",
    ResourceType: "ChangeStatus",
    ApplicationID: getUserInfo()?.ApplicationID,
    ApplicationStageID: getUserInfo()?.ApplicationStageID,
    AccessorId: getUserInfo()?.ApplicantID,
  };
  const handleMoveToDeclaration = async (e) => {
    e.preventDefault();
    const token = getAjiraToken();

    setIsLoading(true);
    try {
      const response = await axios.post(
        url() + "erms_applicationmanagement",
        moveToDeclaration,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const code = response?.data.code;
      if (code == 1) {
        navigate("/declaration");
      }
    } catch (error) {
      setIsLoading(false);
      const msg = error.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
      console.error(error);
    }
  };

  useEffect(() => {
    const Attachment_Table = {
      SystemId: "UHM005",
      ManagementDataType: "Attachment",
      ResourceType: "ForInsertAttachment",
      ApplicationID: getUserInfo()?.ApplicationID,
      AccessorId: getUserInfo()?.ApplicantID,
      ApplicantID: getUserInfo()?.ApplicantID,
    };

    const token = getAjiraToken();
    axios
      .post(url() + "erms_applicationregistration", Attachment_Table, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        var code = response?.data?.code;
        console.log(response.data.data[0]);
        if (code == 1) {
          const data = response?.data;
          const dataArray = data.data;
          const appId = response?.data[0];
          if (dataArray) {
            dataArray?.map((element) => {
              console.log(element.IsUploaded);
              setIsUploaded(element.IsUploaded);
            });
          }
          setData(data.data);
        }
      })
      .catch((err) => {
        const msg = err.message;
        const info = { type: "err", message: msg };
        createNotification(info)();
        console.error(err);
      });
  }, [callBack]);

  const viewCertificate = async (item, i) => {
    // alert(attachmentId)
    const payload = {
      SystemId: "UHM005",
      ManagementDataType: "Attachment",
      ResourceType: "ViewAttachment",
      ApplicationID: "2311IMM-eRMS000002",
      AttachmentID: item?.AttachmentID,
      ApplicationID: getUserInfo()?.ApplicationID,
      ApplicantID: getUserInfo()?.ApplicantID,
      AccessorId: getUserInfo()?.ApplicantID,
    };

    const token = getAjiraToken();

    setisViewCertificateClicked(true);
    // const updatedUploadAttachments = getBase64String(UploadAttachments);

    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setisViewCertificateClicked(false);
      const code = response?.data.code;
      //console.log(response.data.data[0]?.AttachmentPath)
      const attach = response.data.data[0]?.AttachmentPath;
      if (code == 1) {
        setBase64View(attach);
        handleShowCert();
        //closeModal();
        //setcallBack(callBack + 1);
        var msg = response?.data.message;
        const info = { type: "success", message: msg };
        //createNotification(info)();
      } else {
        setisViewCertificateClicked(false);
        var msg = response?.data.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    } catch (error) {
      setisViewCertificateClicked(false);
      const msg = error.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
      console.error(error);
    }
  };

  useEffect(() => {
    // handleSubmitDocument();
  }, []);

  //----------------------------------------Base64_PDF
  const handleFileChanges = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.type !== "application/pdf") {
        setErrorMessage("Invalid file type. Please upload a PDF file.");
        return;
      }
      if (selectedFile.size > 500000) {
        setErrorMessage(
          "File size is too large. Please upload a file smaller than 500KB."
        );
        return;
      }
      setErrorMessage("");
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        let base64File = reader.result;
        base64File.toString();
        setAttachmentPath(base64File);
      };
    }
  };

  return (
    <>
      <Header />
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 module-content">
            <div class="col-sm-12">
              <NavigationSection />
              <Tabs defaultIndex={1}>
                <TabList>
                  <Tab style={{ color: "blue" }} disabled>
                    Application Information
                  </Tab>
                  <Tab style={{ color: "blue" }}>Application Attachments</Tab>
                  <Tab disabled>Applicant Declaration</Tab>
                </TabList>
                <TabPanel></TabPanel>
                <TabPanel></TabPanel>
                <TabPanel></TabPanel>
              </Tabs>
              <div
                className="col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-registration-tab"
                style={{ marginTop: "1px", paddingRight: "1px" }}
              >
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                  <div className="box box-default">
                    <div className="row">
                      <table class="table table-striped custab">
                        <thead>
                          <tr>
                            <th className="text-left" style={{ width: "40%" }}>
                              Attachments
                            </th>
                            {/* <th className="text-left" style={{ width: "20%" }}>
                              Status
                            </th> */}
                            <th className="text-left" style={{ width: "15%" }}>
                              Status
                            </th>
                            <th
                              className="text-center"
                              style={{ width: "15%" }}
                            >
                              Action
                            </th>
                            <th style={{ width: "10%", textAlign: "center" }}>
                              <i className="fa fa-eye"></i>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Data?.map((item, i) => (
                            <tr key={item?.id}>
                              <td class="text-left">{item?.AttachmentName}</td>
                              {/* <td class="text-left">
                                {isLoading ? "Loading..." : "File Upload"}
                              </td> */}
                              <td class="text-left">

                                {isLoading && "Loading..."}
                                {item?.IsUploaded ? (
                                  <span style={{ color: "green" }}>
                                    Uploaded
                                  </span>
                                ) : (
                                  <span style={{ color: "red" }}>
                                    Not uploaded
                                  </span>
                                )}
                              </td>
                              <td class="text-center">
                                {item?.IsUploaded ? (
                                  <Button
                                    variant="success"
                                    onClick={() => openModal(item)}
                                  >
                                    Update
                                  </Button>
                                ) : (
                                  <Button
                                    variant="info"
                                    onClick={() => openModal(item)}
                                  >
                                    Upload
                                  </Button>
                                )}
                              </td>
                              <td className="text-center">
                                {item?.IsUploaded ? (
                                  <Link
                                    onClick={() => viewCertificate(item)}
                                    className="btn text-success"
                                  >
                                    {isViewCertificateClicked
                                      ? "Wait.."
                                      : "View"}
                                  </Link>
                                ) : (
                                  "..."
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Modal show={showModal} onHide={closeModal}>
                        <Modal.Header closeButton>
                          <Modal.Title>Upload: {attachmentName}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form onSubmit={handleSubmitDocument}>
                            <Form.Group controlId="formFile">
                              <Form.Label>{attInfo?.ResourceName}</Form.Label>
                              <br />
                              <Form.Control
                                type="file"
                                onChange={handleFileChanges}
                                required
                              />

                              {errorMessage && (
                                <p style={{ color: "red" }}>{errorMessage}</p>
                              )}
                            </Form.Group>
                            <br />
                            <Button variant="secondary" onClick={closeModal}>
                              Close
                            </Button>{" "}
                            <Button variant="primary" type="submit">
                              Submit
                            </Button>
                          </Form>
                        </Modal.Body>
                      </Modal>
                    </div>
                    <div className="row page-header m-r-t-1"></div>
                    <div className="row">
                      <div className="col-md-4">
                        <Link
                          to="#"
                          className="btn btn-labeled btn-block btn-default"
                        >
                          <i className="fa fa-angle-double-left"></i> | Previous
                        </Link>
                      </div>
                      <div className="col-md-4"></div>
                      <div className="col-md-4">
                        <button
                          disabled={!IsUploaded}
                          onClick={handleMoveToDeclaration}
                          className="btn btn-default btn-block btn-label-next"
                          name="SaveNext"
                        >
                          Next | <i className="fa fa-angle-double-right"></i>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* view certificate modal*/}
      <Modal size="lg" show={showCert} onHide={handleCloseCert}>
        <Form id="cert-view">
          <Modal.Header closeButton>
            <Modal.Title>Certificate name:</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <embed
              src={`data:application/pdf;base64,${base64View}`}
              type="application/pdf"
              width="100%"
              height="600px"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCert}>
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
export default ApplicantAttachments;
