import CryptoJS from "crypto-js";

const funguo =
  "eAjiraStystemDevelopedByMSUYAgoodluckINSP,ApiBASHIRtwahirAINSP,ZABLONmwitaINSP_PoweredBy@ManagmentTeam.ImmigrationDepertment";

export const url = () => {
  // const url = "http://10.6.0.158/eAjiraMngt/api/";
  // const url = "https://10.6.0.125/eAjiraMngtTest/api/";
  // const url = "http://e-recruitment.immigration.go.tz/eAjiraMngt/api/";
  const url = "http://e-recruitment.immigration.go.tz:8082/eAjiraMngt/api/";
  return url;
};

export const urlResource = () => {
  // const url = "http://10.6.0.158/eAjiraMngt/api/";
  // const url = "https://10.6.0.125/tra/api/";
  // const url = "http://e-recruitment.immigration.go.tz/eAjiraMngt/api/";
  const url = "http://e-recruitment.immigration.go.tz:8082/eAjiraMngt/api/";
  return url;
};

//-----------------------NIN
export const setninID = (NIN) => {
  sessionStorage.setItem("NIN", JSON.stringify(NIN));
};
export const getNIN = () => {
  const NIN = JSON.parse(sessionStorage.getItem("NIN"));
  return NIN;
};

//-----------------------DefaultPassword
export const setDefaultPassword = (DefaultPassword) => {
  sessionStorage.setItem("DefaultPassword", JSON.stringify(DefaultPassword));
};
export const getDefaultPassword = () => {
  const DefaultPassword = JSON.parse(sessionStorage.getItem("DefaultPassword"));
  return DefaultPassword;
};

//-----------------------TokenID
export const setAjiraToken = (Token) => {
  localStorage.setItem("Token", JSON.stringify(Token));
};
export const getAjiraToken = () => {
  const Token = JSON.parse(localStorage.getItem("Token"));
  return Token;
};
export const removeAjiraToken = (Token) => {
  localStorage.removeAjiraToken("Token", JSON.stringify(Token));
};

//-----------------------ApplicantID
export const setApplicantID = (ApplicantID) => {
  localStorage.setItem("ApplicantID", JSON.stringify(ApplicantID));
};
export const getApplicantID = () => {
  const ApplicantID = JSON.parse(localStorage.getItem("ApplicantID"));
  return ApplicantID;
};
export const removeApplicantID = (ApplicantID) => {
  localStorage.removeItem("ApplicantID", JSON.stringify(ApplicantID));
};

//-----------------------ApplicationID
export const setApplicationID = (ApplicationID) => {
  localStorage.setItem("ApplicationID", JSON.stringify(ApplicationID));
};
export const getApplicationID = () => {
  const ApplicationID = JSON.parse(localStorage.getItem("ApplicationID"));
  return ApplicationID;
};
export const removeApplicationID = (ApplicationID) => {
  localStorage.removeItem("ApplicationID", JSON.stringify(ApplicationID));
};

//-----------------------StageID
export const setStageID = (StageID) => {
  localStorage.setItem("StageID", JSON.stringify(StageID));
};
export const getStageID = () => {
  const StageID = JSON.parse(localStorage.getItem("StageID"));
  return StageID;
};
export const removeStageID = (StageID) => {
  localStorage.removeItem("StageID", JSON.stringify(StageID));
};

//-----------------------AttachmentStatus
export const setAttachmentStatus = (AttachmentStatus) => {
  localStorage.setItem("AttachmentStatus", JSON.stringify(AttachmentStatus));
};
export const getAttachmentStatus = () => {
  const AttachmentStatus = JSON.parse(localStorage.getItem("AttachmentStatus"));
  return AttachmentStatus;
};
export const removeAttachmentStatus = (AttachmentStatus) => {
  localStorage.removeItem("AttachmentStatus", JSON.stringify(AttachmentStatus));
};

//-----------------------AppStageID
export const setAppStageID = (AppStageID) => {
  if(AppStageID){
    localStorage.setItem("AppStageID", JSON.stringify(AppStageID));
  } else{ localStorage.setItem("AppStageID", JSON.stringify('null'));} 
};
export const getAppStageID = () => {
  const AppStageID = JSON.parse(localStorage.getItem("AppStageID"));
  return AppStageID;
};
export const removeAppStageID = (AppStageID) => {
  localStorage.removeItem("AppStageID", JSON.stringify(AppStageID));
};

//-----------------------UserInfo
export const setUserInfo = (UserInfo) => {
  if (UserInfo) {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(UserInfo),
      funguo
    ).toString();
    localStorage.removeItem("UserInfo");
    localStorage.setItem("UserInfo", JSON.stringify(encryptedData));
  } else {
    localStorage.setItem("UserInfo", JSON.stringify("null"));
  }
};
export const getUserInfo = () => {
  const UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  if (UserInfo) {
    const bytes = CryptoJS.AES.decrypt(UserInfo, funguo);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  }
  return UserInfo;
};

export const removeUserInfo = (UserInfo) => {
  localStorage.removeItem("UserInfo", JSON.stringify(UserInfo));
};

//-----------------------LogOut
export const remSessionLogout = () => {
  sessionStorage.removeItem("UserInfo");
};

//-----------------------Nidas
export const setFirstName = (FirstName) => {
  sessionStorage.setItem("FirstName", FirstName);
};
export const getFirstName = () => {
  const FirstName = sessionStorage.getItem("FirstName");
  return FirstName;
};
export const removeFirstName = (FirstName) => {
  sessionStorage.removeItem("FirstName", JSON.stringify(FirstName));
};
//-----------------------MiddleName
export const setMIddleName = (MIddleName) => {
  sessionStorage.setItem("MIddleName", MIddleName);
};
export const getMIddleName = () => {
  const MIddleName = sessionStorage.getItem("MIddleName");
  return MIddleName;
};
export const removeMIddleName = (MIddleName) => {
  sessionStorage.removeItem("MIddleName", JSON.stringify(MIddleName));
};
//-----------------------SurName
export const setSurName = (SurName) => {
  sessionStorage.setItem("SurName", SurName);
};
export const getSurName = () => {
  const SurName = sessionStorage.getItem("SurName");
  return SurName;
};
export const removeSurName = (SurName) => {
  sessionStorage.removeItem("SurName", JSON.stringify(SurName));
};
//-----------------------Gender
export const setGender = (Gender) => {
  sessionStorage.setItem("Gender", Gender);
};
export const getGender = () => {
  const Gender = sessionStorage.getItem("Gender");
  return Gender;
};
export const removeGender = (Gender) => {
  sessionStorage.removeItem("Gender", JSON.stringify(Gender));
};

//-----------------------DateOfBirth
export const setDateOfBirth = (DateOfBirth) => {
  sessionStorage.setItem("DateOfBirth", DateOfBirth);
};
export const getDateOfBirth = () => {
  const DateOfBirth = sessionStorage.getItem("DateOfBirth");
  return DateOfBirth;
};
export const removeDateOfBirth = (DateOfBirth) => {
  sessionStorage.removeItem("DateOfBirth", JSON.stringify(DateOfBirth));
};

//-----------------------NIN
export const setNin = (Nin) => {
  sessionStorage.setItem("Nin", Nin);
};
export const getNin = () => {
  const Nin = sessionStorage.getItem("Nin");
  return Nin;
};
export const removeNin = (Nin) => {
  sessionStorage.removeItem("Nin", JSON.stringify(Nin));
};

//-----------------------PhotoImage
export const setPHOTO = (PHOTO) => {
  sessionStorage.setItem("PHOTO",PHOTO);
};
export const getPHOTO= () => {
  const PHOTO  = sessionStorage.getItem("PHOTO");
  return PHOTO ;
};
export const removePHOTO= (PHOTO) => {
  sessionStorage.removeItem("PHOTO", JSON.stringify(PHOTO));
};
