import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../Header";
import { Link, useNavigate } from "react-router-dom";
import "../../includes/myStyles.css";
import NavigationSection from "./NavigationSection";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  url,
  getUserInfo,
  urlResource,
  getAjiraToken,
  setAppStageID,
  setUserInfo,
} from "../../utility/Common";
import { Modal, Button, Form } from "react-bootstrap";
import { createNotification } from "../Popup";

function Declaration() {
  const navigate = useNavigate();
  const user_info = getUserInfo();
  const [Data, setData] = useState([]);
  const [ApplicationID, setApplicationID] = useState("");
  const [ApplicantInfo, setApplicantInfo] = useState([]);
  const [ApplicationInformation, setApplicationInformation] = useState([]);
  const [EducationInformation, setEducationInformation] = useState([]);
  const [EducationProfessional, setEducationProfessional] = useState([]);
  const [JKTDetails, setJKTDetails] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ForceNumber, setForceNumber] = useState("");
  const [CompleteYear, setCompleteYear] = useState("");
  const [AttachmentPath, setAttachmentPath] = useState("");
  const [AttachmentPath4, setAttachmentPath4] = useState("");
  const [JKTData, setJKTData] = useState([]);
  const [CampCode, setCampCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [errorMessage3, setErrorMessage3] = useState("");
  const [errorMessage4, setErrorMessage4] = useState("");
  const [currentData, setCurrentData] = useState();
  const [CompletedYear, setCompletedYear] = useState();
  const [updateJKT, setUpdateJKT] = useState(false);
  const handleCloseJKTUpdate = () => setUpdateJKT(false);
  const handleShowJKTUpdate = () => setUpdateJKT(true);
  const [callBack, setcallBack] = useState(false);
  const [CampID, setCampID] = useState();
  const [InstitutionName, setInstitutionName] = useState("");
  const [IndexNo, setIndexNo] = useState("");
  const [ProfessionalID, setProfessionalID] = useState("");
  const [CourseName, setCourseName] = useState("");
  const [EducationID, setEducationID] = useState("");
  const [DocID, setDocID] = useState("");
  const [EducationLevel, setEducationLevel] = useState("");
  const [EducationLevelData, setEducationLevelData] = useState([]);
  const [PerformanceLevelData, setPerformanceLevelData] = useState([]);
  const [PerformanceLevel, setPerformanceLevel] = useState([]);
  const [GradeLevelData, setGradeLevelData] = useState([]);
  const [GradeLevel, setGradeLevel] = useState([]);
  const [SpecialisedData, setSpecialisedData] = useState([]);
  const [Specialised, setSpecialised] = useState("");
  const [updateEducationBackg, setUpdateEducationBackg] = useState(false);
  const handleCloseUpdate = () => setUpdateEducationBackg(false);
  const handleShowUpdate = () => setUpdateEducationBackg(true);
  const [updateProfession, setUpdateProfession] = useState(false);
  const handleCloseProfession = () => setUpdateProfession(false);
  const handleShowProfession = () => setUpdateProfession(true);
  const [Disability, setDisability] = useState("");
  const [PoBox, setPoBox] = useState("");
  const [KoziData, setKoziData] = useState([]);
  const [KoziCode, setKoziCode] = useState([]);
  const [EntryCretariaID, setEntryCretariaID] = useState([]);
  const [EntryCreteriaData, setEntryCreteriaData] = useState([]);
  const [TerritoryID, setTerritoryID] = useState("");
  const [StreetName, setStreetName] = useState("");
  const [TerritoryData, setTerritoryData] = useState([]);
  const [RegionData, setRegionData] = useState([]);
  const [DistrictData, setDistrictData] = useState([]);
  const [WardData, setWardData] = useState([]);
  const [RegionByTerritory, setRegionByTerritory] = useState("");
  const [DistrictByRegion, setDistrictByRegion] = useState("");
  const [WardID, setWardID] = useState([]);
  const [Commissioner, setCommissioner] = useState("");
  const [CommissionerData, setCommissionerData] = useState([]);
  const [ProfessionalAppliedData, setProfessionalAppliedData] = useState([]);
  const [ProfessionalAppliedID, setProfessionalAppliedID] = useState([]);
  const [updateApplication, setUpdateApplication] = useState(false);
  const handleCloseApplication = () => setUpdateApplication(false);
  const handleShowApplication = () => setUpdateApplication(true);
  const [GradeID, setGradeID] = useState();
  const [EducationLevelID, setEducationLevelID] = useState();
  const [FirstName, setFirstName] = useState("");
  const [MiddleName, setMiddleName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Gender, setGender] = useState("");
  const [DateOfBirth, setDateOfBirth] = useState("");
  const [PhotoImage, setPhotoImage] = useState("");
  const [NIN, setNIN] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [BirthCertificateImage, setBirthCertificateImage] = useState("");
  const [Email, setEmail] = useState("");
  const [updatePersonalInfo, setupdatePersonalInfo] = useState(false);
  const handleClosePersonalInfo = () => setupdatePersonalInfo(false);
  const handleShowPersonalInfo = () => setupdatePersonalInfo(true);
  const [errorMsg, setErrorMsg] = useState("");
  const handleShow = () => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
    navigate("/home");
  };

  //----------------------------------------functionBase64String
  // function getBase64String(Personal_Info) {
  //   const pdfDoc = Personal_Info.BirthCertificateImage;
  //   const base64String = pdfDoc.split(",").pop();
  //   Personal_Info.BirthCertificateImage = base64String;

  //   const imgDoc = Personal_Info.PhotoImage;
  //   const base64StringPhotoImage = imgDoc.split(",").pop();
  //   Personal_Info.PhotoImage = base64StringPhotoImage;
  //   return Personal_Info;
  // }

  function getBase64String5(UpdatesEducation) {
    const pdfDoc = UpdatesEducation.AttachmentPath;
    const base64String = pdfDoc.split(",").pop();
    UpdatesEducation.AttachmentPath = base64String;
    return UpdatesEducation;
  }

  //----------------------------------------ViewDeclarationForm
  useEffect(() => {
    const applicationID = getUserInfo().ApplicationID;
    setApplicationID(applicationID);

    const fetchData = async () => {
      const token = getAjiraToken();
      try {
        const response = await axios.post(
          url() + "erms_applicationregistration",
          {
            SystemId: "UHM005",
            ManagementDataType: "ViewApplicationDetails",
            ResourceType: "AllDetails",
            ApplicationID: applicationID,
            AccessorId: getUserInfo()?.ApplicantID,
            ApplicantID: getUserInfo()?.ApplicantID,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const actualData = response?.data?.data[0];
        const {
          ApplicantInfo,
          ApplicationInformation,
          EducationInformation,
          EducationProfessional,
          JKTDetails,
        } = actualData;
        // setcallBack(callBack + 1);
        setApplicantInfo(ApplicantInfo);
        setApplicationInformation(ApplicationInformation);
        setEducationInformation(EducationInformation);
        setEducationProfessional(EducationProfessional);
        setJKTDetails(JKTDetails);

        if (response?.data?.code == 1) {
          const data = response?.data?.data;
          setData(data?.data);
        }
      } catch (error) {
        const msg = error.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    };
    fetchData();
  }, [callBack]);

  //----------------------------------------declarationSubmit
  const declaration = {
    SystemId: "UHM005",
    ManagementDataType: "SubmitApplication",
    ResourceType: "DECLARE",
    ApplicantID: getUserInfo()?.ApplicantID,
    ApplicationID: ApplicationID,
    AccessorId: getUserInfo()?.ApplicantID,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = getAjiraToken();
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        declaration,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const code = response?.data?.code;
      if (code == 1) {
        setcallBack(code + 1);
        const appId = response?.data?.data[0];
        setUserInfo(appId);
        handleShow();
      } else {
        // other response
      }
    } catch (error) {
      const msg = error.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    }
  };
  useEffect(() => {
    // handleSubmit();
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleCompletedYear = (e) => {
    setCompletedYear(e.target.value);
  };

  const handleCompleteYear = (e) => {
    setCompleteYear(e.target.value);
  };

  const startYear = 2000;
  const endYear = new Date().getFullYear();
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index
  );

  //----------------------------------------PersonalInfo_Update
  function setUpdatePersonalInfoData() {
    ApplicantInfo.forEach((rowUpdate) => {
      setNIN(rowUpdate.NIN);
      setFirstName(rowUpdate.FirstName);
      setMiddleName(rowUpdate.MiddleName);
      setLastName(rowUpdate.LastName);
      setDateOfBirth(rowUpdate.DateOfBirth);
      setGender(rowUpdate.Gender);
      setBirthCertificateImage(rowUpdate.BirthCertificateImage);
      setPhotoImage(rowUpdate.PhotoImage);
      setWardID(rowUpdate.WardID);
      setStreetName(rowUpdate.PermanentStreetName);
      setMobileNo(rowUpdate.MobileNo);
      setEmail(rowUpdate.Email);
      handleShowPersonalInfo(true);
    });
  }
  function setUpdateEducationData() {
    EducationInformation.forEach((rowUpdate) => {
      setEducationLevel(rowUpdate.EducationLevelID);
      setInstitutionName(rowUpdate.InstitutionName);
      setIndexNo(rowUpdate.IndexNo);
      setProfessionalID(rowUpdate.ProfessionalID);
      setGradeLevel(rowUpdate.GradeID);
      setCourseName(rowUpdate.CourseName);
      setCompletedYear(rowUpdate.CompletedYear);
      setEducationID(rowUpdate.DocID);
      setDocID(rowUpdate.DocID);
      setCurrentData(rowUpdate.CurrentData);
      setAttachmentPath(rowUpdate.AttachmentPath);
      handleShowUpdate(true);
    });
  }

  //----------------------------------------Application_Update
  function setUpdateApplicationInfo(rowUpdate) {
    setKoziCode(rowUpdate.KoziCode);
    setDisability(rowUpdate.Disability);
    setTerritoryID(rowUpdate.TerritoryID);
    setCommissioner(rowUpdate.Commissioner);
    setWardID(rowUpdate.WardID);
    setStreetName(rowUpdate.StreetName);
    setEntryCretariaID(rowUpdate.EntryCretariaID);
    setProfessionalAppliedID(rowUpdate.ProfessionalAppliedID);
    setPoBox(rowUpdate.PoBox);
    handleShowApplication(true);
  }

  //----------------------------------------VewUpdate_JKT
  function setUpdateJKTData() {
    JKTDetails.forEach((rowUpdate) => {
      setCampCode(rowUpdate.CampCode);
      setForceNumber(rowUpdate.ForceNumber);
      setCompleteYear(rowUpdate.CompleteYear);
      setAttachmentPath(rowUpdate.AttachmentPath);
      setCampID(rowUpdate.CampID);
      handleShowJKTUpdate(true);
    });
  }

  //----------------------------------------VewUpdate_Professional
  function setUpdateProfessionalData() {
    EducationProfessional.forEach((rowUpdate) => {
      handleShowProfession(true);
      setIndexNo(rowUpdate.IndexNo);
      setProfessionalID(rowUpdate.SpecializedID);
      setCourseName(rowUpdate.CourseName);
      setCompletedYear(rowUpdate.CompletedYear);
      setInstitutionName(rowUpdate.InstitutionName);
      setAttachmentPath(rowUpdate.AttachmentPath);
      setEducationID(rowUpdate.EducationID);
      setGradeID(rowUpdate.GradeID);
      setEducationLevelID(rowUpdate.EducationLevelID);
      setProfessionalID(rowUpdate.SpecializedID);
      setDocID(rowUpdate.DocID);
    });
  }

  //----------------------------------------submitPersonalInfo_Updade
  const Personal_Info = {
    SystemId: "UHM005",
    ResourceType: "UPDATE",
    ManagementDataType: "Personal_Info",
    ApplicantID: getUserInfo()?.ApplicantID,
    NIN: user_info.NIN,
    BirthCertificatePath: BirthCertificateImage,
    PhotoPath: PhotoImage,
    WardID,
    StreetName,
    MobileNo,
    Email,
    AccessorId: getUserInfo()?.ApplicantID,
  };

  const handleSubmitPersonalInfo = async (e) => {
    e.preventDefault();
    const token = getAjiraToken();
    const personalInfo = getBase64String2(Personal_Info);
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        personalInfo,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = response?.data;
      const code = data?.code;
      if (code == 1) {
        setcallBack(code);
        var msg = response?.data?.message;
        const info = { type: "success", message: msg };
        createNotification(info)();
        handleClosePersonalInfo();
      } else {
        setIsLoading(false);
        var msg = response?.data?.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    } catch (error) {
      setIsLoading(false);
      const msg = error.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    }
  };

  //----------------------------------------submitApplication_Update
  const NewApplication = {
    SystemId: "UHM005",
    ManagementDataType: "CreateApplication",
    ResourceType: "UpdateApplication",
    ApplicationID,
    Disability,
    WardID,
    TerritoryID,
    StreetName,
    EntryCretariaID,
    PoBox,
    ProfessionalAppliedID,
    AccessorId: getUserInfo()?.ApplicantID,
  };
  const handleSubmitApplication = async (e) => {
    e.preventDefault();
    const token = getAjiraToken();
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        NewApplication,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = response?.data;
      const code = data?.code;
      if (code == 1) {
        setcallBack(code);
        var msg = response?.data?.message;
        const info = { type: "success", message: msg };
        createNotification(info)();
        handleCloseApplication();
      } else {
        setIsLoading(false);
        var msg = response?.data?.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    } catch (error) {
      setIsLoading(false);
      const msg = error.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    }
  };

  //----------------------------------------updatesEducationSubmit
  const UpdatesEducation = {
    SystemId: "UHM005",
    ManagementDataType: "Educational_Backg",
    ResourceType: "UPDATE",
    ApplicantID: getUserInfo()?.ApplicantID,
    InstitutionName,
    IndexNo,
    EducationLevel,
    ProfessionalID,
    EducationLevelID: EducationLevel,
    GradeID: GradeLevel,
    CourseName,
    DocID: EducationID,
    CompletedYear,
    AttachmentPath,
    AccessorId: getUserInfo()?.ApplicantID,
  };

  const handleSubmitUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const token = getAjiraToken();
    const Updates_Education = getBase64String5(UpdatesEducation);
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        Updates_Education,
        NewApplication,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const code = response?.data?.code;
      if (code == 1) {
        handleCloseUpdate();
        setIsLoading(false);
        setcallBack(callBack + 1);
        var msg = response?.data?.message;
        const info = { type: "success", message: msg };
        createNotification(info)();
      } else {
        setIsLoading(false);
        var msg = response?.data?.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    } catch (error) {
      setIsLoading(false);
      const msg = error.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    }
  };

  //----------------------------------------updatesProfessionalSubmit
  const UpdatesProfessional = {
    SystemId: "UHM005",
    ManagementDataType: "Educational_Backg",
    ResourceType: "UPDATE",
    ApplicantID: getUserInfo()?.ApplicantID,
    IndexNo,
    DocID,
    CourseName,
    CompletedYear,
    InstitutionName,
    AttachmentPath,
    GradeID,
    EducationLevelID: EducationLevelID,
    ProfessionalID,
    AccessorId: getUserInfo()?.ApplicantID,
  };

  const handleSubmitUpdateProfession = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const token = getAjiraToken();
    try {
      const response = await axios.post(
        url() + "erms_applicationregistration",
        UpdatesProfessional,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const code = response?.data?.code;
      if (code == 1) {
        handleCloseProfession();
        setIsLoading(false);
        setcallBack(callBack + 1);
        var msg = response?.data?.message;
        const info = { type: "success", message: msg };
        createNotification(info)();
      } else {
        setIsLoading(false);
        var msg = response?.data?.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      }
    } catch (error) {
      setIsLoading(false);
      const msg = error.message;
      const info = { type: "error", message: msg };
      createNotification(info)();
    }
  };

  //----------------------------------------updatesJKTSubmit
  const updatesJKTSubmit = {
    SystemId: "UHM005",
    ManagementDataType: "JKT",
    ResourceType: "UpdateJKT",
    ApplicantID: ApplicationID,
    ForceNumber,
    CampCode,
    CampID: CampID,
    CompleteYear,
    AttachmentPath: AttachmentPath,
    AccessorId: getUserInfo()?.ApplicantID,
  };

  const handleSubmitJKTUpdate = (e) => {
    e.preventDefault();
    const token = getAjiraToken();
    setIsLoading(true);
    axios
      .post(url() + "erms_applicationregistration", updatesJKTSubmit, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setIsLoading(false);
        const code = response?.data?.code;
        if (code == 1) {
          setcallBack(code + 1);
          var msg = response?.data?.message;
          const info = { type: "success", message: msg };
          createNotification(info)();
          handleCloseJKTUpdate();
        } else if (code == 0) {
          var msg = response?.data?.ErrorField;
          const info = { type: "error", message: msg };
          createNotification(info)();
        } else {
          var msg = response?.data?.message;
          const info = { type: "error", message: msg };
          createNotification(info)();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        const msg = error.message;
        const info = { type: "error", message: msg };
        createNotification(info)();
      });
  };

  //----------------------------------------Base64_PDF
  const handleFileChanges = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.type !== "application/pdf") {
        setErrorMessage("Invalid file type. Please upload a PDF file.");
        return;
      }
      if (selectedFile.size > 500000) {
        setErrorMessage(
          "File size is too large. Please upload a file smaller than 500KB."
        );
        return;
      }
      setErrorMessage("");
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        let base64File = reader.result;
        base64File.toString();
        setAttachmentPath(base64File);
      };
    }
  };

  const handleFileChanges2 = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.type !== "application/pdf") {
        setErrorMessage2("Invalid file type. Please upload a PDF file.");
        return;
      }
      if (selectedFile.size > 500000) {
        setErrorMessage2(
          "File size is too large. Please upload a file smaller than 500KB."
        );
        return;
      }
      setErrorMessage2("");
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        let base64File = reader.result;
        base64File.toString();
        setBirthCertificateImage(base64File);
      };
    }
  };

  const handleFileChanges3 = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.type !== "image/jpeg") {
        setErrorMessage3("Invalid file type. Please upload a jpeg file.");
        return;
      }
      if (selectedFile.size > 500000) {
        setErrorMessage3(
          "File size is too large. Please upload a file smaller than 500KB."
        );
        return;
      }
      setErrorMessage3("");
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        let base64File = reader.result;
        base64File.toString();
        setPhotoImage(base64File);
      };
    }
  };

  const handleFileChanges4 = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.type !== "application/pdf") {
        setErrorMessage4("Invalid file type. Please upload a PDF file.");
        return;
      }
      if (selectedFile.size > 500000) {
        setErrorMessage4(
          "File size is too large. Please upload a file smaller than 500KB."
        );
        return;
      }
      setErrorMessage4("");
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        let base64File = reader.result;
        base64File.toString();
        setAttachmentPath4(base64File);
      };
    }
  };

  //----------------------------------------Base64_jpg/png
  const handlePhotoChanges = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.type != "image/jpeg") {
        setErrorMsg("Invalid file type. Please upload a jpg/jpeg file.");
        return;
      }
      if (selectedFile.size > 300000) {
        setErrorMsg(
          "File size is too large. Please upload a file smaller than 300KB."
        );
        return;
      }
      setErrorMsg("");
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        let base64File = reader.result;
        base64File.toString();
        setPhotoImage(base64File);
      };
    }
  };

  //----------------------------------------functionBase64String
  function getBase64String2(Personal_Info) {
    const pdfDoc = Personal_Info.BirthCertificatePath;
    const base64String = pdfDoc.split(",").pop();
    Personal_Info.BirthCertificatePath = base64String;

    const imgDoc = Personal_Info.PhotoPath;
    const base64StringPhotoImage = imgDoc.split(",").pop();
    Personal_Info.PhotoPath = base64StringPhotoImage;
    return Personal_Info;
  }

  // function getBase64String1(AttachmentPaths) {
  //   const pdfDoc = AttachmentPaths.AttachmentPath;
  //   const base64String = pdfDoc.split(",").pop();
  //   AttachmentPaths.AttachmentPath = base64String;

  //   const imgDoc = AttachmentPaths.PhotoImage;
  //   const base64StringPhotoImage = imgDoc.split(",").pop();
  //   AttachmentPaths.PhotoImage = base64StringPhotoImage;
  //   return AttachmentPaths;
  // }

  //----------------------------------------JKTLookUp
  useEffect(() => {
    getCamps();
  }, []);

  function getCamps() {
    var payload = {
      actionid: "Resources",
      ResourceType: "jkt",
      ResourceParam: "",
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setJKTData(data);
      });
  }
  const JKTInputChange = (e) => {
    JKTData?.map((obj) => {
      var jktID = obj?.ResourceName;
      if (e.target.value == jktID) {
        setCampCode(jktID);
      }
    });
  };

  //----------------------------------------getEducationLevel
  useEffect(() => {
    getEducationLevel();
  }, []);

  function getEducationLevel() {
    var payload = {
      actionid: "Resources",
      ResourceType: "educationlevel",
      ResourceParam: "",
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setEducationLevelData(data);
      });
  }
  const EducationLevelInputChange = (e) => {
    EducationLevelData?.map((obj) => {
      var EducationLevelID = obj?.ResourceID;
      if (e.target.value == EducationLevelID) {
        setEducationLevel(EducationLevelID);
        getPerformanceHandler(EducationLevelID);
      }
    });
  };

  //----------------------------------------getPerformance
  function getPerformanceHandler(EducationLevelID) {
    var payload = {
      actionid: "Resources",
      ResourceType: "Performance",
      ResourceParam: EducationLevelID,
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setPerformanceLevelData(data);
      });
  }
  const PerformanceInputChange = (e) => {
    PerformanceLevelData?.map((obj) => {
      var PerformanceID = obj?.ResourceID;
      if (e.target.value == PerformanceID) {
        setPerformanceLevel(PerformanceID);
        getGradeHandler(PerformanceID);
      }
    });
  };

  //----------------------------------------getGrade
  function getGradeHandler(PerformanceID) {
    var payload = {
      actionid: "Resources",
      ResourceType: "grade",
      ResourceParam: PerformanceID,
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setGradeLevelData(data);
      });
  }
  const GradeInputChange = (e) => {
    GradeLevelData?.map((obj) => {
      var GradeID = obj?.ResourceID;
      if (e.target.value == GradeID) {
        setGradeLevel(GradeID);
      }
    });
  };

  //----------------------------------------getSpecialised
  useEffect(() => {
    getSpecialised();
  }, []);

  function getSpecialised() {
    var payload = {
      actionid: "Resources",
      ResourceType: "Professional",
      ResourceParam: "",
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setSpecialisedData(data);
      });
  }
  const SpecialisedInputChange = (e) => {
    SpecialisedData?.map((obj) => {
      var SpecialisedID = obj?.ResourceID;
      if (e.target.value == SpecialisedID) {
        setSpecialised(SpecialisedID);
      }
    });
  };

  //----------------------------------------ProfessionalAppliedFor
  useEffect(() => {
    getProfessionalApplied();
  }, []);

  function getProfessionalApplied() {
    var payload = {
      actionid: "Resources",
      ResourceType: "ProfessionalApplied",
      ResourceParam: "",
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setProfessionalAppliedData(data);
      });
  }
  const ProfessionalAppliedInputChange = (e) => {
    ProfessionalAppliedData?.map((obj) => {
      var ProfessionalID = obj?.ResourceID;
      if (e.target.value == ProfessionalID) {
        setProfessionalAppliedID(ProfessionalID);
      }
    });
  };

  //----------------------------------------CommissionerHO/HQ
  useEffect(() => {
    getCommissioner();
  }, []);

  function getCommissioner() {
    var payload = {
      actionid: "Resources",
      ResourceType: "AppliedFrom",
      ResourceParam: "",
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setCommissionerData(data);
      });
  }
  const CommissionerInputChange = (e) => {
    CommissionerData?.map((obj) => {
      var commissionerID = obj?.ResourceID;
      if (e.target.value == commissionerID) {
        setCommissioner(commissionerID);
      }
    });
  };

  //----------------------------------------JobTittle(Kozi)
  useEffect(() => {
    getJobTittle();
  }, []);

  function getJobTittle() {
    var payload = {
      actionid: "Resources",
      ResourceType: "kozi",
      ResourceParam: "",
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setKoziData(data);
      });
  }
  const KoziInputChange = (e) => {
    KoziData?.map((obj) => {
      var KoziCode = obj?.ResourceID;
      if (e.target.value == KoziCode) {
        setKoziCode(KoziCode);
      }
    });
  };

  //----------------------------------------EntryCreteria
  useEffect(() => {
    getEntryCreteria();
  }, []);

  function getEntryCreteria() {
    var payload = {
      actionid: "Resources",
      ResourceType: "EntryCreteria",
      ResourceParam: "",
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setEntryCreteriaData(data);
      });
  }
  const EntryCreteriaInputChange = (e) => {
    EntryCreteriaData?.map((obj) => {
      var entryCreteriaId = obj?.ResourceID;
      if (e.target.value == entryCreteriaId) {
        setEntryCretariaID(entryCreteriaId);
      }
    });
  };

  //----------------------------------------territory
  useEffect(() => {
    getterritories();
  }, []);

  function getterritories() {
    var payload = {
      actionid: "Resources",
      ResourceType: "Territory",
      ResourceParam: "",
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setTerritoryData(data);
      });
  }
  const TerritoryInputChange = (e) => {
    TerritoryData?.map((obj) => {
      var territoryId = obj?.ResourceID;
      if (e.target.value == territoryId) {
        setTerritoryID(territoryId);
        getRegionHandler(territoryId);
      }
    });
  };

  //----------------------------------------Region
  function getRegionHandler(territoryId) {
    var payload = {
      actionid: "Resources",
      ResourceType: "RegionByTerritory",
      ResourceParam: territoryId,
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setRegionData(data);
      });
  }
  const RegionInputChange = (e) => {
    RegionData?.map((obj) => {
      var RegionID = obj?.ResourceID;
      if (e.target.value == RegionID) {
        setRegionByTerritory(RegionID);
        getDistrictHandler(RegionID);
      }
    });
  };

  //----------------------------------------District
  function getDistrictHandler(RegionID) {
    var payload = {
      actionid: "Resources",
      ResourceType: "DistrictByRegion",
      ResourceParam: RegionID,
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setDistrictData(data);
      });
  }
  const DistrictInputChange = (e) => {
    DistrictData?.map((obj) => {
      var DistrictID = obj?.ResourceID;
      if (e.target.value == DistrictID) {
        setDistrictByRegion(DistrictID);
        getWardHandler(DistrictID);
      }
    });
  };

  //----------------------------------------Ward
  function getWardHandler(DistrictID) {
    var payload = {
      actionid: "Resources",
      ResourceType: "WardByDistrict",
      ResourceParam: DistrictID,
      ResourceParam2: "",
    };
    fetch(urlResource() + "ajira_activity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((response) => {
        var data = response?.data;
        setWardData(data);
      });
  }

  const WardInputChange = (e) => {
    WardData?.map((obj) => {
      var WardByDistrictID = obj?.ResourceID;
      if (e.target.value == WardByDistrictID) {
        setWardID(WardByDistrictID);
      }
    });
  };

  //----------------------------------------BirthDate_Validation
  // const [dateError, setDateError] = useState("");
  // const handleDateChange = (e) => {
  //   const inputDate = new Date(e.target.value);
  //   const currentDate = new Date();
  //   const minDate = new Date(
  //     currentDate.getFullYear() - 33,
  //     currentDate.getMonth(),
  //     currentDate.getDate()
  //   );
  //   const maxDate = new Date(
  //     currentDate.getFullYear() - 18,
  //     currentDate.getMonth(),
  //     currentDate.getDate()
  //   );
  //   if (inputDate >= minDate && inputDate <= maxDate) {
  //     setDateOfBirth(e.target.value);
  //     setDateError("");
  //   } else {
  //     setDateError("Tafadhali hakikisha umri wako ni kati ya miaka 18-33 tu");
  //   }
  // };

  return (
    <>
      <Header />
      <Modal show={showModal} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p
              className="text-default h3"
              style={{ fontFamily: "Yu Gothic Light" }}
            >
              Application Submission
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p
            className="text-default h4 text-center"
            style={{ fontFamily: "Yu Gothic Light" }}
          >
            Your Successfully Submitted your Application
          </p>
          <br />
          <p className="text-primary h4 text-center">
            View your Profile to check Application status
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 module-content">
            <div class="col-sm-12">
              <NavigationSection />
              <Tabs defaultIndex={2}>
                <TabList>
                  <Tab style={{ color: "blue" }} disabled>
                    Application Information
                  </Tab>
                  <Tab style={{ color: "blue" }} disabled>
                    Application Attachments
                  </Tab>
                  <Tab style={{ color: "blue" }}>Applicant Declaration</Tab>
                </TabList>
                <TabPanel></TabPanel>
                <TabPanel></TabPanel>
                <TabPanel></TabPanel>
              </Tabs>
              <div
                className="col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-registration-tab"
                style={{ marginTop: "1px", paddingRight: "1px" }}
              >
                <br />
                <div className="tab-registration-content active">
                  <form id="step-one-form" onSubmit={handleSubmit}>
                    <input type="hidden" name="_csrf" value="" />

                    <div className="box box-default">
                      {ApplicantInfo?.map((obj) => (
                        <div className="box panel panel-default" key={obj?.id}>
                          <div className="box-header with-border panel-heading">
                            <h3 className="box-title">
                              A: &nbsp; Personal information
                            </h3>
                            <Link
                              key={obj?.id}
                              className="btn pull-right btn-success btn-xs"
                              onClick={() => setUpdatePersonalInfoData(obj)}
                            >
                              <i className="fa fa-edit"></i> Edit
                            </Link>
                          </div>
                          <div className="box-body panel-body">
                            <div className="row">
                              <div className="col-sm-2 declaration-div">
                                <img
                                  src={`data:image/jpeg;base64,${obj?.PhotoPath}`}
                                  alt="picture"
                                  className="img-fluid mx-auto d-block max-width-100 h-100"
                                />
                              </div>
                              <br />
                              <div className="col-sm-10">
                                <div className="row">
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b
                                        text-uppercase
                                        style={{ fontSize: "13px" }}
                                      >
                                        First Name
                                      </b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.FirstName}
                                      </p>
                                    </span>
                                  </div>
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>
                                        Middle Name
                                      </b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.MiddleName}
                                      </p>
                                    </span>
                                  </div>
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>
                                        Surname
                                      </b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.LastName}
                                      </p>
                                    </span>
                                  </div>
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>
                                        Date of Birth
                                      </b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.DateOfBirth}
                                      </p>
                                    </span>
                                  </div>
                                </div>
                                <br />
                                <div className="row">
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>
                                        Gender{" "}
                                      </b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.Gender}
                                      </p>
                                    </span>
                                  </div>
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>
                                        Telephone No.
                                      </b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.MobileNo}
                                      </p>
                                    </span>
                                  </div>
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>
                                        Place of Birth
                                      </b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.TerritoryName}
                                      </p>
                                    </span>
                                  </div>
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>Region</b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.PermanentRegionName}
                                      </p>
                                    </span>
                                  </div>
                                </div>
                                <br />
                                <div className="row">
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>
                                        District
                                      </b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.PermanentDistrictName}
                                      </p>
                                    </span>
                                  </div>
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>
                                        Ward/Shehia
                                      </b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.PermanentWardName}
                                      </p>
                                    </span>
                                  </div>
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>
                                        Village/Stree
                                      </b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.PermanentStreetName}
                                      </p>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      {ApplicationInformation?.map((obj) => (
                        <div className="box panel panel-default" key={obj?.id}>
                          <div className="box-header with-border panel-heading">
                            <h3 className="box-title">
                              B: &nbsp;Application Information
                            </h3>
                            <Link
                              key={obj?.id}
                              className="btn pull-right btn-success btn-xs"
                              onClick={() => setUpdateApplicationInfo(obj)}
                            >
                              <i className="fa fa-edit"></i> Edit
                            </Link>
                          </div>
                          <div className="box-body panel-body">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="row">
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>
                                        Apply to CGI/CIZ
                                      </b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.Commissioner}
                                      </p>
                                    </span>
                                  </div>
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>
                                        Employment Post
                                      </b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.AjiraTajwa}
                                      </p>
                                    </span>
                                  </div>
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>
                                        Education level apply for:
                                      </b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.CreteriaName}
                                      </p>
                                    </span>
                                  </div>
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>
                                        Profession Applied For:
                                      </b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.ProfessionalAppliedName}
                                      </p>
                                    </span>
                                  </div>
                                </div>
                                <br />

                                <div className="row">
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>
                                        Current Residence
                                      </b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.TerritoryName}
                                      </p>
                                    </span>
                                  </div>
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>Region</b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.RegionName}
                                      </p>
                                    </span>
                                  </div>
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>
                                        District
                                      </b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.DistrictName}
                                      </p>
                                    </span>
                                  </div>
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>
                                        Ward/Shehia
                                      </b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.WardName}
                                      </p>
                                    </span>
                                  </div>
                                </div>
                                <br />

                                <div className="row">
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>
                                        Village/Street
                                      </b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.StreetName}
                                      </p>
                                    </span>
                                  </div>
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>
                                        Disability
                                      </b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.Disability}
                                      </p>
                                    </span>
                                  </div>
                                  <div className="col-sm-3">
                                    <span style={{ lineHeight: "1.7em" }}>
                                      <b style={{ fontSize: "13px" }}>Adress</b>
                                      <br />
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                      >
                                        {obj?.PoBox}
                                      </p>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="box panel panel-default">
                        <div className="box-header with-border panel-heading">
                          <h3 className="box-title">
                            C: &nbsp;Education Background
                          </h3>
                          <Link
                            onClick={() => setUpdateEducationData()}
                            className="btn pull-right btn-success btn-xs"
                          >
                            <i className="fa fa-edit"></i> Edit
                          </Link>
                        </div>
                        <div className="box-body panel-body">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="row">
                                <div className="col-sm-3">
                                  <span style={{ lineHeight: "1.7em" }}>
                                    <b style={{ fontSize: "13px" }}>
                                      Education Level
                                    </b>
                                    <br />
                                    {EducationInformation?.map((obj) => (
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                        key={obj?.id}
                                      >
                                        {obj?.LevelName}
                                      </p>
                                    ))}
                                  </span>
                                </div>
                                <div className="col-sm-3">
                                  <span style={{ lineHeight: "1.7em" }}>
                                    <b style={{ fontSize: "13px" }}>
                                      Registration No.
                                    </b>
                                    <br />
                                    {EducationInformation?.map((obj) => (
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                        key={obj?.id}
                                      >
                                        {obj?.IndexNo}
                                      </p>
                                    ))}
                                  </span>
                                </div>
                                <div className="col-sm-3">
                                  <span style={{ lineHeight: "1.7em" }}>
                                    <b style={{ fontSize: "13px" }}>
                                      School/College
                                    </b>
                                    <br />
                                    {EducationInformation?.map((obj) => (
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                        key={obj?.id}
                                      >
                                        {obj?.InstitutionName}
                                      </p>
                                    ))}
                                  </span>
                                </div>
                                <div className="col-sm-3">
                                  <span style={{ lineHeight: "1.7em" }}>
                                    <b style={{ fontSize: "13px" }}>
                                      Completed Year
                                    </b>
                                    <br />
                                    {EducationInformation?.map((obj) => (
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                        key={obj?.id}
                                      >
                                        {obj?.CompletedYear}
                                      </p>
                                    ))}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="box panel panel-default">
                        <div className="box-header with-border panel-heading">
                          <h3 className="box-title">D: &nbsp;Profession</h3>

                          <Link
                            onClick={() => setUpdateProfessionalData()}
                            className="btn pull-right btn-success btn-xs"
                          >
                            <i className="fa fa-edit"></i> Edit
                          </Link>
                        </div>
                        <div className="box-body panel-body">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="row">
                                <div className="col-sm-3">
                                  <span style={{ lineHeight: "1.7em" }}>
                                    <b style={{ fontSize: "13px" }}>
                                      Qualified Certificate Name
                                    </b>
                                    <br />
                                    {EducationProfessional?.map((obj) => (
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                        key={obj?.id}
                                      >
                                        {obj?.CourseName}
                                      </p>
                                    ))}
                                  </span>
                                </div>
                                <div className="col-sm-3">
                                  <span style={{ lineHeight: "1.7em" }}>
                                    <b style={{ fontSize: "13px" }}>
                                      Registration No.
                                    </b>
                                    <br />
                                    {EducationProfessional?.map((obj) => (
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                        key={obj?.id}
                                      >
                                        {obj?.IndexNo}
                                      </p>
                                    ))}
                                  </span>
                                </div>
                                <div className="col-sm-3">
                                  <span style={{ lineHeight: "1.7em" }}>
                                    <b style={{ fontSize: "13px" }}>
                                      Institution Name
                                    </b>
                                    <br />
                                    {EducationProfessional?.map((obj) => (
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                        key={obj?.id}
                                      >
                                        {obj?.InstitutionName}
                                      </p>
                                    ))}
                                  </span>
                                </div>
                                <div className="col-sm-3">
                                  <span style={{ lineHeight: "1.7em" }}>
                                    <b style={{ fontSize: "13px" }}>
                                      Completed Year
                                    </b>
                                    <br />
                                    {EducationProfessional?.map((obj) => (
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                        key={obj?.id}
                                      >
                                        {obj?.CompletedYear}
                                      </p>
                                    ))}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="box panel panel-default">
                        <div className="box-header with-border panel-heading">
                          <h3 className="box-title">
                            E: &nbsp;JKT/JKU Information
                          </h3>
                          <Link
                            onClick={() => setUpdateJKTData()}
                            className="btn pull-right btn-success btn-xs"
                          >
                            <i className="fa fa-edit"></i> Edit
                          </Link>
                        </div>
                        <div className="box-body panel-body">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="row">
                                <div className="col-sm-3">
                                  <span style={{ lineHeight: "1.7em" }}>
                                    <b style={{ fontSize: "13px" }}>
                                      Force Number
                                    </b>
                                    <br />
                                    {JKTDetails?.map((obj) => (
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                        key={obj?.id}
                                      >
                                        {obj?.ForceNumber}
                                      </p>
                                    ))}
                                  </span>
                                </div>
                                <div className="col-sm-3">
                                  <span style={{ lineHeight: "1.7em" }}>
                                    <b style={{ fontSize: "13px" }}>
                                      Camp Name
                                    </b>
                                    <br />
                                    {JKTDetails?.map((obj) => (
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                        key={obj?.id}
                                      >
                                        {obj?.CampCode}
                                      </p>
                                    ))}
                                  </span>
                                </div>
                                {/* <div className="col-sm-3">
                                  <span style={{ lineHeight: "1.7em" }}>
                                    <b style={{ fontSize: "13px" }}>
                                      Camp Name
                                    </b>
                                    <br />
                                    {JKTDetails?.map((obj) => (
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                        key={obj?.id}
                                      >
                                        {obj?.CampName}
                                      </p>
                                    ))}
                                  </span>
                                </div> */}
                                <div className="col-sm-3">
                                  <span style={{ lineHeight: "1.7em" }}>
                                    <b style={{ fontSize: "13px" }}>
                                      Completed Year
                                    </b>
                                    <br />
                                    {JKTDetails?.map((obj) => (
                                      <p
                                        className="text-uppercase"
                                        style={{ fontSize: "13px" }}
                                        key={obj?.id}
                                      >
                                        {obj?.CompleteYear}
                                      </p>
                                    ))}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="box panel panel-success">
                        <div className="box-header with-border panel-heading">
                          <input
                            type="checkbox"
                            style={{ zoom: "1.7" }}
                            id="myCheckbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                          />
                          <p style={{ fontSize: "16px" }}>
                            I declare the above information is true to the best
                            of my knowledge.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="box-footer">
                      <div className="row">
                        <div className="col-md-4">
                          <Link
                            to="/home"
                            className="btn btn-labeled btn-block btn-default"
                          >
                            <i className="fa fa-home"></i> | Dashboard
                          </Link>
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                          <button
                            className="btn btn-default btn-block btn-label-next"
                            name="SaveNext"
                            id="myButton"
                            disabled={!isChecked}
                          >
                            Save and Exit | <i className="fa fa-save"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*----------------------------------------- Update_PersonalInfo*/}
      <Modal
        size="lg"
        show={updatePersonalInfo}
        onHide={handleClosePersonalInfo}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Personal Info.</Modal.Title>
        </Modal.Header>
        <Form id="personalInfo" onSubmit={handleSubmitPersonalInfo}>
          <Modal.Body>
            <div className="box-body">
              <div className="row">
                <div className="tab-registration-content active">
                  <div className="box box-default">
                    <div className="box-body">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group field-Territory required">
                            <label
                              htmlFor="Territory"
                              className="control-label"
                            >
                              Residence
                            </label>
                            <select
                              id="TerritoryID"
                              name="TerritoryID"
                              className="form-control text-uppercase"
                              value={TerritoryID}
                              onChange={(e) => TerritoryInputChange(e)}
                              required
                            >
                              <option>Select ..</option>
                              {TerritoryData?.map((obj) => {
                                return (
                                  <option
                                    key={obj?.ResourceID}
                                    value={obj?.ResourceID}
                                  >
                                    {obj?.ResourceName}
                                  </option>
                                );
                              })}
                            </select>
                            <p className="help-block help-block-error"></p>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group field-Region required">
                            <label htmlFor="Region" className="control-label">
                              Region
                            </label>
                            <select
                              id="RegionID"
                              name="RegionByTerritory"
                              className="form-control text-uppercase"
                              value={RegionByTerritory}
                              onChange={(e) => RegionInputChange(e)}
                              required
                            >
                              <option>Select ..</option>
                              {RegionData?.map((obj) => {
                                return (
                                  <option
                                    key={obj?.ResourceID}
                                    value={obj?.ResourceID}
                                  >
                                    {obj?.ResourceName}
                                  </option>
                                );
                              })}
                            </select>
                            <p className="help-block help-block-error"></p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group field-District. required">
                            <label htmlFor="District" className="control-label">
                              District
                            </label>
                            <select
                              id="DistrictID"
                              name="DistrictByRegion"
                              className="form-control text-uppercase"
                              onChange={(e) => DistrictInputChange(e)}
                              value={DistrictByRegion}
                              required
                            >
                              <option>Select ..</option>
                              {DistrictData?.map((obj) => {
                                return (
                                  <option
                                    key={obj?.ResourceID}
                                    value={obj?.ResourceID}
                                  >
                                    {obj?.ResourceName}
                                  </option>
                                );
                              })}
                            </select>
                            <p className="help-block help-block-error"></p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group field-Ward required">
                            <label htmlFor="Ward" className="control-label">
                              Ward/Shehia
                            </label>
                            <select
                              id="WardID"
                              name="WardByDistrict"
                              className="form-control text-uppercase"
                              onChange={(e) => WardInputChange(e)}
                              value={WardID}
                              required
                            >
                              <option>Select ..</option>
                              {WardData?.map((obj) => {
                                return (
                                  <option
                                    key={obj?.ResourceID}
                                    value={obj?.ResourceID}
                                  >
                                    {obj?.ResourceName}
                                  </option>
                                );
                              })}
                            </select>
                            <p className="help-block help-block-error"></p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group field-field-checkNo. required">
                            <label
                              htmlFor="StreetName"
                              className="control-label"
                            >
                              Village/Street
                            </label>
                            <input
                              type="text"
                              id=""
                              className="form-control text-uppercase"
                              name="StreetName"
                              onChange={(e) => setStreetName(e.target.value)}
                              value={StreetName}
                              required
                            />
                            <p className="help-block help-block-error"></p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group field-field-PhotoImage. required">
                            <label
                              htmlFor="PhotoImage"
                              className="control-label"
                            >
                              Photo/Image
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              name="PhotoImage"
                              onChange={handleFileChanges3}
                            />
                            {errorMessage3 && (
                              <p style={{ color: "red" }}>{errorMessage3}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="box box-default">
                    <div className="box-body">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group field-field-checkNo. required">
                            <label
                              htmlFor="StreetName"
                              className="control-label"
                            >
                              Email
                            </label>
                            <input
                              type="email"
                              id=""
                              className="form-control"
                              title="Email Format: ajira@gmail.com"
                              placeholder="ajira@gmail.com"
                              name="Email"
                              onChange={(e) => setEmail(e.target.value)}
                              value={Email}
                              required
                            />
                            <p className="help-block help-block-error"></p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group field-MobileNo required">
                            <label htmlFor="MobileNo" className="control-label">
                              Phone
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="MobileNo"
                              placeholder="0754125125"
                              title="PhoneNo. Format: 0754125125"
                              onChange={(e) => setMobileNo(e.target.value)}
                              value={MobileNo}
                              required
                            />
                            <p className="help-block help-block-error"></p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group field-field-BirthCertificateImage. required">
                            <label
                              htmlFor="BirthCertificateImage"
                              className="control-label"
                            >
                              Birth Certificate
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              name="BirthCertificateImage"
                              onChange={handleFileChanges2}
                            />
                            {errorMessage2 && (
                              <p style={{ color: "red" }}>{errorMessage2}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={handleClosePersonalInfo}
                    >
                      Close
                    </Button>
                    <Button variant="primary" type="submit">
                      {isLoading ? "Loading.." : "Submit"}
                    </Button>
                  </Modal.Footer>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Form>
      </Modal>

      {/*----------------------------------------- Application_Info*/}
      <Modal size="lg" show={updateApplication} onHide={handleCloseApplication}>
        <Modal.Header closeButton>
          <Modal.Title>Update Job Application Info.</Modal.Title>
        </Modal.Header>
        <Form id="updateBackg" onSubmit={handleSubmitApplication}>
          <Modal.Body>
            <div className="box box-default">
              <br />
              <div className="row">
                <div className="col-md-4">
                  <label
                    className="control-label"
                    for="passportpersonalandcitizenship-maritalstatusid"
                  >
                    Apply to CGI/CIZ
                  </label>
                  <select
                    id="commissionerID"
                    name="Commissioner"
                    className="form-control text-uppercase"
                    value={Commissioner}
                    onChange={(e) => CommissionerInputChange(e)}
                    required
                  >
                    <option>Select HO/HQ..</option>
                    {CommissionerData?.map((obj) => {
                      return (
                        <option key={obj?.ResourceID} value={obj?.ResourceID}>
                          {obj?.ResourceName}
                        </option>
                      );
                    })}
                  </select>
                  <p className="help-block help-block-error"></p>
                </div>

                <div className="col-md-4">
                  <label
                    className="control-label"
                    for="passportpersonalandcitizenship-maritalstatusid"
                  >
                    Employment post
                  </label>
                  <select
                    id="KoziCode"
                    name="kozi"
                    className="form-control text-uppercase"
                    value={KoziCode}
                    onChange={(e) => KoziInputChange(e)}
                    required
                  >
                    <option>Select..</option>
                    {KoziData?.map((obj) => {
                      return (
                        <option key={obj?.ResourceID} value={obj?.ResourceID}>
                          {obj?.ResourceName}
                        </option>
                      );
                    })}
                  </select>
                  <p className="help-block help-block-error"></p>
                </div>

                <div className="col-md-4">
                  <label
                    className="control-label"
                    for="passportpersonalandcitizenship-maritalstatusid"
                  >
                    Education level apply for:
                  </label>
                  <select
                    id="entryCreteriaId"
                    name="EntryCretariaID"
                    className="form-control text-uppercase"
                    value={EntryCretariaID}
                    onChange={(e) => EntryCreteriaInputChange(e)}
                    required
                  >
                    <option>Select HO/HQ..</option>
                    {EntryCreteriaData?.map((obj) => {
                      return (
                        <option key={obj?.ResourceID} value={obj?.ResourceID}>
                          {obj?.ResourceName}
                        </option>
                      );
                    })}
                  </select>
                  <p className="help-block help-block-error"></p>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <label
                    className="control-label"
                    for="passportpersonalandcitizenship-maritalstatusid"
                  >
                    Professional applied for:
                  </label>
                  <select
                    id="ProfessionalID"
                    name="ProfessionalAppliedID"
                    className="form-control text-uppercase"
                    value={ProfessionalAppliedID}
                    onChange={(e) => ProfessionalAppliedInputChange(e)}
                    required
                  >
                    <option>Select..</option>
                    {ProfessionalAppliedData?.map((obj) => {
                      return (
                        <option key={obj?.ResourceID} value={obj?.ResourceID}>
                          {obj?.ResourceName}
                        </option>
                      );
                    })}
                  </select>
                  <p className="help-block help-block-error"></p>
                </div>
              </div>
              <br />

              <div className="box-header with-border">
                <h3 className="box-title">Current Residence</h3>
              </div>
              <br />
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group field-field-checkNo. required">
                    <label
                      className="control-label"
                      for="passportpersonalandcitizenship-occupation"
                    >
                      Residence
                    </label>
                    <select
                      id="TerritoryID"
                      name="TerritoryID"
                      className="form-control text-uppercase"
                      value={TerritoryID}
                      onChange={(e) => TerritoryInputChange(e)}
                      required
                    >
                      <option>Select ..</option>
                      {TerritoryData?.map((obj) => {
                        return (
                          <option key={obj?.ResourceID} value={obj?.ResourceID}>
                            {obj?.ResourceName}
                          </option>
                        );
                      })}
                    </select>
                    <p className="help-block help-block-error"></p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group field-field-checkNo. required">
                    <label
                      className="control-label"
                      for="passportpersonalandcitizenship-occupation"
                    >
                      Region
                    </label>
                    <select
                      id="RegionID"
                      name="RegionByTerritory"
                      className="form-control text-uppercase"
                      value={RegionByTerritory}
                      onChange={(e) => RegionInputChange(e)}
                      required
                    >
                      <option>Select..</option>
                      {RegionData?.map((obj) => {
                        return (
                          <option key={obj?.ResourceID} value={obj?.ResourceID}>
                            {obj?.ResourceName}
                          </option>
                        );
                      })}
                    </select>
                    <p className="help-block help-block-error"></p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group field-field-checkNo. required">
                    <label
                      className="control-label"
                      for="passportpersonalandcitizenship-occupation"
                    >
                      District
                    </label>
                    <select
                      id="DistrictID"
                      name="DistrictByRegion"
                      className="form-control text-uppercase"
                      onChange={(e) => DistrictInputChange(e)}
                      value={DistrictByRegion}
                      required
                    >
                      <option>Select..</option>
                      {DistrictData?.map((obj) => {
                        return (
                          <option key={obj?.ResourceID} value={obj?.ResourceID}>
                            {obj?.ResourceName}
                          </option>
                        );
                      })}
                    </select>
                    <p className="help-block help-block-error"></p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group field-field-checkNo. required">
                    <label
                      className="control-label"
                      for="passportpersonalandcitizenship-occupation"
                    >
                      Ward/Shehia
                    </label>
                    <select
                      id="WardByDistrictID"
                      name="WardID"
                      className="form-control text-uppercase"
                      onChange={(e) => WardInputChange(e)}
                      value={WardID}
                      required
                    >
                      <option>Select..</option>
                      {WardData?.map((obj) => {
                        return (
                          <option key={obj?.ResourceID} value={obj?.ResourceID}>
                            {obj?.ResourceName}
                          </option>
                        );
                      })}
                    </select>
                    <p className="help-block help-block-error"></p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group field-field-checkNo. required">
                    <label
                      className="control-label"
                      for="passportpersonalandcitizenship-occupation"
                    >
                      Village/Street
                    </label>
                    <input
                      type="text"
                      id=""
                      className="form-control text-uppercase"
                      name="StreetName"
                      onChange={(e) => setStreetName(e.target.value)}
                      value={StreetName}
                      required
                    />
                    <p className="help-block help-block-error"></p>
                  </div>
                </div>
                <div className="col-md-4">
                  <label
                    className="control-label"
                    for="passportpersonalandcitizenship-maritalstatusid"
                  >
                    Disability
                  </label>

                  <select
                    value={Disability}
                    onChange={(e) => setDisability(e.target.value)}
                    id="DisabilityID"
                    name="Disability"
                    className="form-control"
                    required
                  >
                    <option value="">Disability?</option>
                    <option value="0">NO</option>
                    <option value="1">YES</option>
                  </select>
                  <p className="help-block help-block-error"></p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="form-group field-field-checkNo. required">
                  <label
                    className="control-label"
                    for="passportpersonalandcitizenship-occupation"
                  >
                    Address
                  </label>
                  <textarea
                    type="text"
                    style={{ textTransform: "uppercase" }}
                    id="PoBoxID"
                    className="form-control"
                    name="PoBox"
                    onChange={(e) => setPoBox(e.target.value)}
                    value={PoBox}
                    required
                  />
                  <p className="help-block help-block-error"></p>
                </div>
              </div>
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseApplication}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                {isLoading ? "Loading.." : "Submit"}
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Form>
      </Modal>

      {/*----------------------------------------- Update_EducationBackg*/}
      <Modal size="lg" show={updateEducationBackg} onHide={handleCloseUpdate}>
        <Modal.Header closeButton>
          <Modal.Title>Update Education Background</Modal.Title>
        </Modal.Header>
        <Form id="updateBackg" onSubmit={handleSubmitUpdate}>
          <Modal.Body>
            <div className="box-body">
              <div className="row">
                <div className="tab-registration-content active">
                  <input type="hidden" name="_csrf" value="" />
                  <div className="box box-default">
                    <div className="box-body">
                      <div className="row">
                        <div
                          class="box-body"
                          style={{
                            display: "block",
                          }}
                        >
                          <div class="row">
                            <div class="col-md-12">
                              <br />

                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    Education Level
                                    <select
                                      id="EducationLevelID"
                                      className="form-control text-uppercase"
                                      name="EducationLevel"
                                      value={EducationLevel}
                                      onChange={(e) =>
                                        EducationLevelInputChange(e)
                                      }
                                      required
                                    >
                                      <option>Select..</option>
                                      {EducationLevelData?.map((obj) => {
                                        return (
                                          <option
                                            key={obj?.ResourceID}
                                            value={obj?.ResourceID}
                                          >
                                            {obj?.ResourceName}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <p className="help-block help-block-error"></p>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group field-InstitutionName. required">
                                    College / School Name
                                    <input
                                      type="text"
                                      className="form-control text-uppercase"
                                      name="InstitutionName"
                                      onChange={(e) =>
                                        setInstitutionName(e.target.value)
                                      }
                                      value={InstitutionName}
                                      aria-required="true"
                                    />
                                    <p className="help-block help-block-error"></p>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-3">
                                  <div className="form-group field-IndexNo required">
                                    IndexNo./ RegNo.
                                    <input
                                      type="text"
                                      className="form-control text-uppercase"
                                      placeholder="S0149/0011/2018"
                                      name="IndexNo"
                                      onChange={(e) =>
                                        setIndexNo(e.target.value)
                                      }
                                      value={IndexNo}
                                      aria-required="true"
                                    />
                                    <p className="help-block help-block-error"></p>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group field-GpaID required">
                                    Education Performance
                                    <select
                                      id="PerformanceID"
                                      className="form-control text-uppercase"
                                      name="Performance"
                                      value={PerformanceLevel}
                                      onChange={(e) =>
                                        PerformanceInputChange(e)
                                      }
                                      required
                                    >
                                      <option>Select..</option>
                                      {PerformanceLevelData?.map((obj) => {
                                        return (
                                          <option
                                            key={obj?.ResourceID}
                                            value={obj?.ResourceID}
                                          >
                                            {obj?.ResourceName}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <p className="help-block help-block-error"></p>
                                  </div>
                                </div>

                                <div className="col-md-3">
                                  <div className="form-group field-pfNo. required">
                                    Grade
                                    <select
                                      id="GradeLevel"
                                      className="form-control text-uppercase"
                                      name="GradeID"
                                      value={GradeLevel}
                                      onChange={(e) => GradeInputChange(e)}
                                      required
                                    >
                                      <option>Select..</option>
                                      {GradeLevelData?.map((obj) => {
                                        return (
                                          <option
                                            key={obj?.ResourceID}
                                            value={obj?.ResourceID}
                                          >
                                            {obj?.ResourceName}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <p className="help-block help-block-error"></p>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group field-pfNo. required">
                                    Specialised
                                    <select
                                      id="Specialised"
                                      className="form-control text-uppercase"
                                      name="SpecialisedID"
                                      value={Specialised}
                                      onChange={(e) =>
                                        SpecialisedInputChange(e)
                                      }
                                    >
                                      <option>Select..</option>
                                      {SpecialisedData?.map((obj) => {
                                        return (
                                          <option
                                            key={obj?.ResourceID}
                                            value={obj?.ResourceID}
                                          >
                                            {obj?.ResourceName}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <p className="help-block help-block-error"></p>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group field-MembershipNo required">
                                    Qualified Certificate Name
                                    <input
                                      type="text"
                                      className="form-control text-uppercase"
                                      name="CourseName"
                                      placeholder="CSEE"
                                      onChange={(e) =>
                                        setCourseName(e.target.value)
                                      }
                                      value={CourseName}
                                      aria-required="true"
                                    />
                                    <p className="help-block help-block-error"></p>
                                  </div>
                                </div>

                                <div className="col-md-3">
                                  <div className="form-group field-pfNo. required">
                                    Completed Year
                                    <select
                                      className="form-control text-uppercase"
                                      name="EducationLevel"
                                      value={CompletedYear}
                                      onChange={(e) => handleCompletedYear(e)}
                                      required
                                    >
                                      <option value="" disabled>
                                        Select..
                                      </option>
                                      {years?.map((year) => (
                                        <option key={year} value={year}>
                                          {year}
                                        </option>
                                      ))}
                                    </select>
                                    <p className="help-block help-block-error"></p>
                                  </div>
                                </div>

                                <div className="col-md-3">
                                  <div className="form-group field-MembershipNo required">
                                    Attachment Certificate
                                    <input
                                      type="file"
                                      className="form-control"
                                      name="AttachmentPath"
                                      onChange={handleFileChanges}
                                      aria-required="true"
                                    />
                                    {errorMessage && (
                                      <p
                                        style={{
                                          color: "red",
                                        }}
                                      >
                                        {errorMessage}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUpdate}>
                      Close
                    </Button>
                    <Button variant="primary" type="submit">
                      {isLoading ? "Loading.." : "Submit"}
                    </Button>
                  </Modal.Footer>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Form>
      </Modal>

      {/*----------------------------------------- UpdateJKT*/}
      <Modal size="lg" show={updateJKT} onHide={handleCloseJKTUpdate}>
        <Modal.Header closeButton>
          <Modal.Title>Update JKT/JKU Infomation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="box-body">
            <div className="row">
              <form id="jktUpdate" onSubmit={handleSubmitJKTUpdate}>
                <div className="box-body">
                  <div className="row">
                    <div class="box-body" style={{ display: "block" }}>
                      <div class="row">
                        <div class="col-md-12">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label
                                  htmlFor="BirthCertificateImage"
                                  className="control-label"
                                >
                                  Training Camp
                                </label>
                                <select
                                  className="form-control text-uppercase"
                                  name="EducationLevel"
                                  value={CampCode}
                                  onChange={(e) => JKTInputChange(e)}
                                  required
                                >
                                  <option>Select ..</option>
                                  {JKTData?.map((obj) => {
                                    return (
                                      <option
                                        key={obj?.ResourceID}
                                        value={obj?.ResourceName}
                                      >
                                        {obj?.ResourceName}
                                      </option>
                                    );
                                  })}
                                </select>
                                <p className="help-block help-block-error"></p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group field-MembershipNo required">
                                <label
                                  htmlFor="BirthCertificateImage"
                                  className="control-label"
                                >
                                  Force Number
                                </label>
                                <input
                                  type="text"
                                  className="form-control text-uppercase"
                                  name="ForceNumber"
                                  placeholder="Force number.."
                                  onChange={(e) =>
                                    setForceNumber(e.target.value)
                                  }
                                  value={ForceNumber}
                                  aria-required="true"
                                />
                                <p className="help-block help-block-error"></p>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label
                                  htmlFor="BirthCertificateImage"
                                  className="control-label"
                                >
                                  Year of Completion
                                </label>
                                <select
                                  className="form-control"
                                  name="EducationLevel"
                                  value={CompleteYear}
                                  onChange={(e) => handleCompleteYear(e)}
                                  required
                                >
                                  <option value="" disabled>
                                    Select Year
                                  </option>
                                  {years?.map((year) => (
                                    <option key={year} value={year}>
                                      {year}
                                    </option>
                                  ))}
                                </select>
                                <p className="help-block help-block-error"></p>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group field-MembershipNo required">
                                <label
                                  htmlFor="BirthCertificateImage"
                                  className="control-label"
                                >
                                  Attachment Certificate
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  name="AttachmentPath"
                                  onChange={handleFileChanges}
                                  aria-required="true"
                                />
                                {errorMessage && (
                                  <p
                                    style={{
                                      color: "red",
                                    }}
                                  >
                                    {errorMessage}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseJKTUpdate}>
                    Close
                  </Button>
                  <Button variant="primary" type="submit">
                    {isLoading ? "Loading.." : "Submit"}
                  </Button>
                </Modal.Footer>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*----------------------------------------- Update Professional*/}
      <Modal size="lg" show={updateProfession} onHide={handleCloseProfession}>
        <Modal.Header closeButton>
          <Modal.Title>Update Proffesional Certifications</Modal.Title>
        </Modal.Header>
        <Form id="Update_Profession" onSubmit={handleSubmitUpdateProfession}>
          <Modal.Body>
            <div className="box box-default">
              <div className="box-body">
                <div className="row">
                  <div class="box-body" style={{ display: "block" }}>
                    <div class="row">
                      <div class="col-md-12">
                        <br />
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group field-MembershipNo required">
                              Qualified Certificate Name
                              <input
                                type="text"
                                className="form-control text-uppercase"
                                name="CourseName"
                                placeholder="Cisco Certified Network Professional (CCNP)"
                                onChange={(e) => setCourseName(e.target.value)}
                                value={CourseName}
                                aria-required="true"
                              />
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group field-pfNo. required">
                              Institution Name
                              <input
                                type="text"
                                className="form-control text-uppercase"
                                name="InstitutionName"
                                onChange={(e) =>
                                  setInstitutionName(e.target.value)
                                }
                                value={InstitutionName}
                                aria-required="true"
                              />
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group field-MembershipNo required">
                              Registration No.
                              <input
                                type="text"
                                className="form-control text-uppercase"
                                placeholder="CSCO123456789"
                                name="IndexNo"
                                onChange={(e) => setIndexNo(e.target.value)}
                                value={IndexNo}
                                aria-required="true"
                              />
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group field-pfNo. required">
                              Specialised
                              <select
                                id="SpecialisedID"
                                className="form-control text-uppercase"
                                name="SpecialisedID"
                                value={ProfessionalID}
                                onChange={(e) => SpecialisedInputChange(e)}
                              >
                                <option>Select..</option>
                                {SpecialisedData?.map((obj) => {
                                  return (
                                    <option
                                      key={obj?.ResourceID}
                                      value={obj?.ResourceID}
                                    >
                                      {obj?.ResourceName}
                                    </option>
                                  );
                                })}
                              </select>
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group field-pfNo. required">
                              Completed Year
                              <select
                                className="form-control text-uppercase"
                                name="EducationLevel"
                                value={CompletedYear}
                                onChange={(e) => handleCompletedYear(e)}
                                required
                              >
                                <option value="" disabled>
                                  Select..
                                </option>
                                {years?.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                              <p className="help-block help-block-error"></p>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group field-MembershipNo required">
                              Attachment Certificate
                              <input
                                type="file"
                                className="form-control"
                                name="AttachmentPath"
                                onChange={handleFileChanges}
                                aria-required="true"
                              />
                              {errorMessage && (
                                <p
                                  style={{
                                    color: "red",
                                  }}
                                >
                                  {errorMessage}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseProfession}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
export default Declaration;
